.container-information {
  display: flex;
  width: 320px;
  padding: 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  background: #2e3c46;
  user-select: none;
  margin-top: 3em;
}

.title-information {
  color: var(--gray-100, #eceeef);
  font-family: Raleway;
  font-size: 21.62px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.268px; /* 140% */
}

.txt-information {
  color: var(--gray-100, #eceeef);
  font-family: Raleway;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.266px; /* 140% */
}

.btn-information {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 24px;
  background: #ff8166;
  color: var(--gray-900, #1a2228);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px; /* 140% */
  cursor: pointer;
}

.lbl-information {
  color: var(--orange-200, #ffcabf);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px; /* 140% */
  cursor: pointer;
}

@media (min-width: 768px) {
  .container-information {
    padding: 32px 40px;
    width: 440px;
  }
  
}