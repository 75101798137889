/* @import url('https://fonts.googleapis.com/css2?family=Barlow&family=Raleway&display=swap'); */

body {
  margin: 0;
  font-family: 'Raleway', 'Barlow', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #2E3C46 0%, #425563 52.84%);
  user-select: none;
}

#root {
  width: 100%;
  min-height: 100vh;
  background: url('../images/background_interconectar.svg') no-repeat center bottom;
  background-size: contain;
  background-attachment: fixed; /* Fijar la imagen de fondo */
}
