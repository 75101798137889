/* .landing-page {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
} */
.row_app {
  padding: 72px 20px 0;
}
.landing__container{
  max-width: 1226px;
  margin: 0 auto;
}

.container-news-sgg {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.content-news-sgg {
  display: flex;
  max-width: auto;
  margin-top: 1em;
}

.landing__row {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.main {
  flex-grow: 1;
  flex-basis: auto;
  margin-right: 1.2em;
  width: 50em;
}

.suggestions {
  flex-shrink: 0;
  flex-basis: auto;
  width: 25.4375em;
}

@media (max-width: 70em) {
  .content-news-sgg {
    display: flex;
    flex-direction: column;
  }

  .suggestions {
    width: 100%;
    margin-top: 2em;
    margin-left: 0%;
  }
}


@media (min-width: 768px) {}

@media (min-width: 1024px) {
  .landing__row{
    display: flex;
    flex-direction: row;
    gap: 0;
    flex-wrap: wrap;
  }
  .landing__col.landing__flex {
      width: auto;
      flex: 1;
      width: 66.66666667%;
      padding: 0 24px 0 0;
  }

  .landing__col.landing__fixed {
      flex: 1;
      width: auto;
      width: 33.33333333%;
  }
}

@media (min-width: 1440px) {}