.CustomizedSelect__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #425563;
  height: 50px;
  width: 50px;
  border-radius: 12%;
}

.CustomizedSelect__title {
  margin-bottom: 1px;
  color: #9ea8af;
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.018px;
  margin-left: 0.5em;
  padding-top: 0.2em;
}

