
.pdf_a4.hidden {
    display: none;
}
#divToPrint{
    position: absolute;
    z-index: -2; 
}
.pdf_a4 {
    /*width: 794px;  Or width: 100%; */ 
    /*height: 1123px;  Or height: 100%; */
    width: 595px; /* diseno */ 
    height: 816px; /* diseno */
    background: #D4D8DB;
    padding: 0; 
    background: url('../../assets/images/A4-bg.webp') no-repeat 0 0;
    background-size: cover;
    position: relative;

}
#divToPrint.show ,
.pdf_a4.show {
    display: block;
    z-index: 2;
    position: relative;
}

.pdf_a4 .producto {
    display: flex;
    font-size: 12px;
}


.pdf_a4_header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 14px;
    border-bottom: 1px solid #D4D8DB;
}
.pdf_a4_header--mb{
    margin-bottom: 16px;
}

.pdf_a4_header_title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: #1A2228;
    margin: 0;
}

.pdf_a4_header_info {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 13px;
    color: #5E6F7A;
}

.pdf_a4_header_date {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 13px;
  color: #5E6F7A;
  border-left: 1px solid #5E6F7A;
  padding-left: 7px;
  margin-left: 7px;
}

.pdf_a4_header_col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pdf_a4_header_data {
    display: flex;
    
}

.pdf_a4_content{
    display: flex;
    flex-direction: column;
    width: 372px;
    /* height: 205px; */
    border-radius: 5px;
    background: #ECEEEF;
    margin: 12px 12px 140px;
    padding: 16px;
}
.pdf_a4_content_title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1A2228;
    margin: 0 0 4px;
}

.pdf_a4_content_subtitle {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    color: #68796F;
    margin: 0 0 4px;
}

.pdf_a4_table {
    display: flex;
    flex-direction: row; 
    gap: 11px; 
    margin: 0 0 16px;
}

.pdf_a4_table_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    background: #D4D8DB;
    border-radius: 4px;
    width: auto; 
    flex: 1;

}

.pdf_a4_table_box_num {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 13px;
    color: #2E3C46;
}

.pdf_a4_table_box_text {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 10px;
    color: #2E3C46;
}

.pdf_a4_table_box_feature {
    width: 98px; 
    background-color: #CEDFD5; 
    flex: 45px;
}


.pdf_a4_details_content{
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.pdf_a4_details {
    display: flex;
    gap: 10px; 
    align-items: flex-start; 
}

.pdf_a4_details_col:nth-child(1) {
    width: 80px; 
}

.pdf_a4_details_col:nth-child(2) {
    
    width: 120px; 
}

.pdf_a4_details_col:nth-child(3) {
    width: 120px; 
}

.pdf_a4_details_col {
    box-sizing: border-box; 
    gap: 4px;
    display: flex;
    flex-direction: column;
}

.pdf_a4_details_title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 11px;
    color: #425563;
    margin:0;
}

p.pdf_a4_details_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 11px;
    color: #425563;
    margin:0;
}
.pdf_a4_details_text{
    display: flex;
    flex-direction: row;
    gap: 5px;
}


.pdf_a4_terms{
    margin: 0 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.pdf_a4_terms_content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.pdf_a4_terms span{
        
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 7px;
    line-height: 10px;
    color: #FF5C39;
}
h5.pdf_a4_terms_subtitle {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 7px;
    line-height: 10px;
    color: #1A2228;
    margin: 0;
}
h4.pdf_a4_terms_title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 11px;
    color: #2E3C46;
    margin: 0;
}

.pdf_a4_terms p {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 10px;
    color: #2E3C46;
    margin: 0;
}
.pdf_a4_alert {
    margin: 10px 12px 0;
    border: 1px solid #5E6F7A;
    border-radius: 5.57812px;
    padding: 3px 12px 7px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    align-content: center;
}

p.pdf_a4_alert_text {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 7px;
    line-height: 10px;
    color: #2E3C46;
    margin: 4px 0 0;
}
.pdf_a4_footer {
    position: absolute;
    bottom: 12px;
    left: 12px;
    right: 12px;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

span.pdf_a4_footer_link {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    color: #1A2228;
}

span.pdf_a4_footer_ig {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 11px;
    color: #1A2228;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    gap: 3px;
}

span.pdf_a4_footer_in {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 11px;
    color: #1A2228;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    gap: 3px;
}

.pdf-cubicaje{
    background: url('../../assets/images/bg-pdf-cubicaje.webp') no-repeat 0 0;
    background-size: cover;
}

.pdf-cubicaje .pdf_a4_content{
    width: 332px;
}

.pdf-cubicaje .pdf_a4_details_col:nth-child(1) {
    width: 100px;
}
.pdf-cubicaje .pdf_a4_details_col:nth-child(2) {
    width: 150px;
}


/* Test exportador */

.pdf-test{
    background: url('../../assets/images/bg-pdf-test.webp') no-repeat 0 0;
    background-size: cover;
}

.pdf-test .pdf_a4_content{
    width: 372px;
}

.pdf_a4_test_top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.pdf_a4_test_top_img {
    max-width: 56px;
}



p.pdf_a4_content_characteristics_title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #1A2228;
    margin: 0;
}

.pdf_a4_content_characteristics_text {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #425563;
    margin: 0;
}

.pdf_a4_content_characteristics_circle {
    background-color: #ff8166;
    width: 4px;
    height: 4px;
    border-radius: 100px;
}

.pdf_a4_content_characteristics_item {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 5px;
}

.pdf_a4_content_characteristics {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 8px 0 10px ;
}



.pdf_a4_content_summary {
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    color: #425563;
    padding: 8px 10px;
    background: #CEDFD5;
    border-radius: 6px;
    margin: 0 0 13px;
}

.pdf_a4_content_summary p {
    margin: 0;
}


.pdf_a4_content_description {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 11px;
    color: #425563;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.pdf_a4_content_description p {
    margin: 0;
}

.pdf_a4_content_description p:empty {
    display: none;
}


.pdf_a4_footer_text {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 10px;
    color: #2E3C46;
}
.pdf_a4_footer_text span{
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 7px;
    line-height: 10px;
    color: #FF5C39;
}
.pdf_a4_footer_text p {
    margin: 0;
}
.pdf-test .pdf_a4_alert{
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
}
.pdf-test .pdf_a4_footer{
    flex-direction: column;
}
.pdf-test .pdf_a4_footer_bottom{
    bottom: 12px;
    left: 12px;
    display: flex;
    flex-direction: row;
    gap: 15px;
}



.pdf-test .main-section-container{
    margin: 0 12px;
    width: auto;
}
.pdf-test .pdf_a4_content {
    margin-bottom: 15px;
}
.pdf-test .section-subtitle {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    color: #8BA293;
    margin: 0 0 11px;
}
.pdf-test .services-cards-container {
    gap: 8px;
    align-items: flex-start; 
}
.pdf-test .card-service-item-container{
    background-color: #edeeef;
    padding: 6px 12px 10px;
    color: black;
    border-radius: 6px;
}

.pdf-test .papper-icon {
    width: 33px;
    margin: 0 0 -5px -10px;
}

.pdf-test .card-service-item-container h4 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    color: #1A2228;
    margin: 0 0 12px;
    border-radius: 6px;
}
.pdf-test .option-check-container{
    margin: 0;
    align-items: center;
}
.card-service-item-container > div {
    display: flex;
    flex-direction: column;
    gap: 9px;
}
.pdf-test .card-service-item-container p {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 11px;
    color: #425563;
    margin-left: 5px;
    flex: 1; 
}

.pdf-test .option-check-container img {
    object-fit: cover;
    height: 8px;
    width: 8px; 
    flex-shrink: 0; 
}
.pdf-test .see-details-link{
    display: none;
}

.pdf_a4_comparator{
    background: url('../../assets/images/bg-pdf-comparator.webp') no-repeat 0 0;
    background-size: cover;
}
.pdf_a4_hoja{
    padding-top: 16px;
}
.pdf_a4_bg{
    background: #ECEEEF;
    border-radius: 5px;
    padding: 15px;
    margin: 0 12px 0;
}
.pdf_a4_pagina_productos{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.pdf_a4_producto{
    background: white;
    display: flex;
    border-radius: 4px;
    font-size: 8px;
    flex-direction: row;
    padding: 8px;
    gap: 12px;
}
.pdf_a4_head{
    display: flex;
    gap: 12px;
    margin: 0 0 3px;
}
.pdf_a4_head_title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 11px;
    color: #1A2228;
}
.pdf_a4_head .pdf_a4_col_prices{
    border: 0;
}
.pdf_a4_image_container {
    position: relative;
    width: 37px; 
    height: 37px; 
    background-color: #ddd; 
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 125px;
}

.pdf_a4_producto-imagen {
    display: block;
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.pdf_a4_image_placeholder {
    display: none; /* Ocultar por defecto */
    width: 100%;
    height: 100%;
    background-color: #ccc; /* Fondo gris claro */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777; /* Texto opcional */
    font-size: 14px;
}
.pdf_a4_col_image{
    flex: 0 0 37px;
}
.pdf_a4_col_data{
    flex: 0 0 107px;
}

.col.pdf_a4_col_data {
    flex: 0 0 106px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pdf_a4_producto-name{
    color: #1A2228;
    font-family: 'Barlow';
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.pdf_a4_producto-subtitle{
    color: #1A2228;
    font-family: 'Barlow';
    font-size: 7.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    margin: 0;
}
.pdf_a4_producto-description{
    color: #1A2228;
    font-family: 'Barlow';
    font-size: 6.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 9px;
    margin: 0;
}
.pdf_a4_col_prices{
    flex: 0 0 80px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    border: 1px solid #DFEAE3;
    padding: 0 10px;
    border-radius: 4px;
}
.pdf_a4_col_prices_filled{
    background: #DFEAE3;
    /* border: 0;     */
}
.pdf_a4_row {
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
}
.pdf_a4_col_prices_num{
    color: #1A2228;
    font-family: 'Barlow';
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
}
.pdf_a4_col_prices-bold{
    font-weight: 700;
}
.pdf_a4_col_prices_size {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 5.57812px;
    line-height: 8px;
    color: #1A2228;
    background: #BED5C6;
    padding: 2px;
    border-radius: 2px;
}
.pdf_a4_col_flag,
.pdf_a4_col_brand{
    flex: 0 0 49px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.pdf_a4_col_brand_img,
.pdf_a4_col_flag_img {
    object-fit: cover;
    border-radius: 2px;
}
.pdf_a4_col_segment_title,
.pdf_a4_col_flag_title,
.pdf_a4_col_brand_title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 11px;
    color: #1A2228;
    margin: 0 0 3px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.pdf_a4_col_segment{
    flex: 0 0 49px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.pdf_a4_footer_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pdf_a4_footer--col {
    justify-content: space-between;
    bottom: 8px;
}


.pdf_a4_footer_paginator {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 11px;
    color: #1A2228;
    display: flex;
    align-items: center;
    gap: 3px;
}

.pdf_a4_footer_paginator_actual {
    background: #ECEEEF;
    border: 1px solid #7B8892;
    width: 18px;
    height: 15px;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    line-height: 13px;
}

.pdf_a4_image_feature {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
    display: block;
}

.pdf_a4_topcontent_title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1A2228;
    margin: 0;
}

.pdf_a4_topcontent_desc {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    color: #68796F;
    margin: 0 0 10px;
}
.pdf_a4_topcontent{
    margin: 0 -15px;
    padding: 0 15px 12px;
    border-bottom: 1px solid #BBC2C7;
}
.pdf_a4_image_feature{
    width: 100%;
    height: 208px;
    object-fit: cover;
    border-radius: 2px;
}
.pdf_a4_topcontent_total{
    margin: 10px 0 0;
    display: block;
    color: #1A2228;
    font-family: 'Barlow';
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
}
.pdf_a4_last .pdf_a4_alert {
    margin: 0;
}

.pdf_a4_last .pdf_a4_footer {
    flex-direction: column;
}

.pdf_a4_last .pdf_a4_footer_bottom {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}
h3.pdf_a4_last_title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 11px;
  color: #2E3C46;
}
.pdf_a4_last_text{
  color: #2E3C46;
  font-family: 'Barlow';
  font-size: 6.27539px;
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.pdf_a4_last_text p {
  margin: 0;
}

.pdf_a4_last_text h4 {

    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 7.06098px;
    line-height: 10px;
    color: #1A2228;
    margin: 0;
}
.pdf_a4_last_text  h5 {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 6.27539px;
    line-height: 9px;
    color: #2E3C46;
    margin: 0;
}
.pdf_a4_last_text span{
        
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    
    color: #FF5C39;
}