.preferences__principal-text {
  text-align: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.preferences__title {
  color: var(--gray-100, #eceeef);
  font-family: Raleway;
  font-size: 19.22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.908px;
  /* 140% */
  display: block;
  margin-bottom: 1em;
}

.preferences__subtitle {
  color: var(--gray-300, #bbc2c7);
  text-align: center;
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.926px;
  /* 140% */
  display: block;
}

.preferences__subtitle-text-image {
  color: var(--gray-300, #bbc2c7);
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.926px;
}

.preferences__title-image {
  color: var(--gray-300, #bbc2c7);
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.926px;
  margin-bottom: 1em;
}

.preferences__tag-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  overflow: hidden;
  overflow-y: auto;
}

.preferences__tag-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.preferences__container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preferences__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preferences__tag {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--gray-700, #425563);
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.9px; /* 140% */
  cursor: pointer;
  transition: .2s all ease-out;
  
}

.preferences__tag-title {
  color: var(--gray-100, #eceeef);
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.266px;
}

.preferences__tag--selected {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--green-400, #bed5c6);
  color: var(--gray-900, #1a2228);
  font-family: Barlow;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.9px; /* 140% */
}

.mainPreferencesContainer {
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 0px;
  max-width: 600px;
}

.pencilIconImage {
  border: 1px solid #425563;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: .2s all ease-out;
}
.pencilIconImage:hover{
  background: #425563;
}

.pencilIconImage img {
  width: 11px;
}

.preferencesChildrenItemsContainer {
  flex-wrap: wrap;
}

.preferencesDetailContainer {
  height: 400px;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .mainPreferencesContainer{
    padding: 80px 0 60px;
  }
  
  .preferences__principal-subtitle{
    max-width: 800px;
  }
  .preferences__title-image{
    margin-bottom: 32px;
  }
  .preferences__btn-text {
    margin-top: 35px;
  }
  
  
}