.results_row{
    color: #e3efea;
 
    margin: 50px auto 0;
    max-width: 1180px;
    width: 100%;
}
.results_row-loading{
    margin-top: 0;
}
.results_container {
    padding: 20px 20px;
    max-width: 100%;
    width: 100%;
    background: #2e3c46;
    border-radius: 12px;
}
h2.results_title {
    color: var(--gray-100, #eceeef);
    font-family: Barlow;
    font-size: 34.64px;
    font-style: normal;
    font-weight: 700;
    line-height: 48.496px;
}

p.results_cant {
    color: var(--gray-200, #d4d8db);
    font-family: Barlow;
    font-size: 17.09px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.926px;
}
.results_figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    border-radius: 8px;
    cursor: pointer;
    background: white;
}

.results_data {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

h3.results_item_title {
    color: #fff;
    font-family: Raleway;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    cursor: pointer;
}

p.results_item_text {
    color: var(--gray-200, #d4d8db);
    font-family: Barlow;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
    cursor: pointer;
}

.results_item + .results_item {
    border-top: 1px solid #d4d8db;
    padding-top: 25px;
}


@media (min-width: 768px) {
    .results_item {
        display: flex;
        flex-direction: row;
        gap: 24px;
    }
        
    .results_figure {
        height: 175px;
        width: 217px;
        aspect-ratio: 175 / 217;
        margin-bottom: 25px;
        flex: 0 0 217px;
    }
    
    p.results_cant {
        margin-bottom: 30px;
    }
}
