.terminosServicio-information__principal-title span:first-child {
  text-align: center;
  color: var(--gray-100, #eceeef);
  font-family: Barlow;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.terminosServicio-information__principal-title p {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 140% */
}
.terminosServicio-information__principal--text{
  margin-top: 20px;
}

.sectionMainContainer{
  display: flex;
  flex-direction: column; 
}
.servicesMainContainer {
  flex-direction: row;
  justify-content: space-between;
  max-width: 1216px;
  margin: 20px auto 0;
}
.sectionMainContainer-mt{
  margin-top: 30px;
}

.tabText {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.activeTab {
  border-bottom: 3px solid #ff5c39;
  cursor: pointer;
}

.inactiveTab {
  border-bottom: 3px solid transparent;
  cursor: pointer;
}

.containerServices {
  display: flex;
  max-width: 1216px;
  margin: 0 auto;
}
.container_tabs{
  overflow: scroll;
  padding-left: 0;
  white-space: nowrap;
  gap: 30px;
  width: 100%;

}

.servicesMainContainer__img{
  max-width: 200px;
  margin: 0 auto 40px;
  display: block;
}



@media (min-width: 768px) {
 
}

@media (min-width: 1024px) {  
  .servicesMainContainer{
    display: flex;
    flex-wrap: wrap;
  }
  
  .servicesMainContainer__img{
    max-width: inherit;
  }
  .activeTab, .inactiveTab{
    padding-left: 30px;
    padding-right: 30px;
  }
  
}

@media (min-width: 1440px) {
}