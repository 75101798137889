.banner__container {
  color: var(--orange-100, #ffefeb);
  font-family: Raleway;
  font-size: 2.727em;
  font-style: normal;
  font-weight: 700;
  line-height: 3em;
  
  max-width: 1216px;
  margin: 0 auto;
  padding: 20px 0 40px;
}
.banner__row{
  display: flex;
  flex-direction: column;
}
.banner__image {
  text-align: right;
  max-width: 320px;
  margin: 25px auto 0;
}

.banner__img {
  width: 100%;
  height: 100%;
}

.banner__title {
  color: var(--orange-100, #ffefeb);
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;

}

.banner__description {
  color: var(--gray-300, #bbc2c7);
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.926px; /* 140% */
}






@media (min-width: 768px) {
  .banner__col{
    flex:1;
    
  }
  .banner__row{
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }

  

}

@media (min-width: 1024px) {
  .banner__image {
    max-width: 458px;
  }
  .banner__col--flex{
    flex: 2;
    max-width: 760px;
    padding-left: 50px;
    padding-right: 40px;
  }
  
  .banner__title {
    margin: 0 0 18px;
  }
  
}

@media (min-width: 1440px) {
  .banner__col--flex{
    
    padding-left: 90px;
    padding-right: 80px;
  }
}