aside {
  display: flex;
  margin-left: 1em;
  gap: 1em;
  margin-top: 3%;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 17.875em;
  min-width: 200px;
  max-width: 300px;
  height: auto;
  filter: drop-shadow(0px 8px 24px rgba(26, 34, 40, 0.2));
  flex: none;
  flex-grow: 0;
  background-color: transparent;
  overflow: hidden;
  margin: 0px 24px 0px 0px;
}


.card .card-1 {
  order: 0;
}

.frame-35.card-1 {
  background: #ffa692;
}

.exam-time {
  width: 122px;
  height: 90px;
  background: url("../../assets/images/test-exportador.png");
  flex: none;
  order: 0;
  flex-grow: 0;
  background-repeat: no-repeat;
}


.frame-36.card-1 {
  display: flex; /* Usa flexbox para controlar el diseño */
  flex-direction: column; /* Apila los elementos verticalmente */
  align-items: flex-start; /* Alinea los elementos a la izquierda */
  padding: 20px; /* Ajusta el padding según sea necesario */
  gap: 16px; /* Espacio entre los elementos internos */
  width: 100%; /* Ajusta el ancho según sea necesario */
  height: auto; /* Usa la altura automática para ajustarse al contenido */
  box-sizing: border-box; /* Incluye el padding en el ancho y la altura */
  background-color: #ffcabf; /* Color de fondo */
  overflow: hidden; /* Oculta el contenido que se desborde */
}

/* Frame 37 */
.frame-37 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  max-width: 246px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.test-exporter {
  max-width: 246px;
  height: 24px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 17.09px;
  line-height: 24px;
  color: #1a2228;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.description-text {

  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 15.19px;
  line-height: 19px;
  color: #425563;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.test-button {
  height: 22px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 15.19px;
  line-height: 21px;
  color: #eceeef;
  flex: none;
  order: 0;
  flex-grow: 1;
}

/* Texto descriptivo sobre qué es esta herramienta. */
.texto-descriptivo {
  /* height: 38px; */
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 15.19px;
  line-height: 19px;
  color: #425563;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

}

/* Frame 8 */
.btn-ver {
  display: flex;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;
  background: var(--gray-800, #2e3c46);
  cursor: pointer;
}

.btn-ver:hover {
  border-radius: 21px;
  background: var(--gray-900, #1a2228);
}

.txt-ver {
  color: var(--gray-100, #eceeef);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px; /* 140% */
  flex: 1 0 0;
}

/* Card 2 */
/* Frame 35 Color*/


.delivery-truck {
  background: url("../../assets/images/calculadora-paletizado.png");
  width: 139px;
  height: 90px;
  flex: none;
  order: 0;
  flex-grow: 0;
  background-repeat: no-repeat;
}

.price-calculator {
  background: url("../../assets/images/calculadora-precios.svg");
  width: 199px;
  height: 90px;
  flex: none;
  order: 0;
  flex-grow: 0;
  background-repeat: no-repeat;
}


/* Calculadora de precios */
.calculadora-precios {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 17.09px;
  line-height: 24px;
  color: #1a2228;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  
}

/* Calcular precios */
.calcular-precios {
  width: 174px;
  height: 22px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 15.19px;
  line-height: 21px;
  color: #eceeef;
  flex: none;
  order: 0;
  flex-grow: 1;
}

/* Card 3 */
.card .card-3 {
  order: 2;
}

.price-comparsion {
  background: url("../../assets/images/compador-precios.svg");
  width: 164px;
  height: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


/* Calculadora de precios competitivos */
.calculadora-de-precios {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 17.09px;
  line-height: 24px;
  color: #1a2228;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Calcular precios */
.calcular-precios {
  width: 174px;
  height: 22px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 15.19px;
  line-height: 21px;
  color: #eceeef;
  flex: none;
  order: 0;
  flex-grow: 1;
}


/* Calculadora de cubicaje */
.calculadora-de-cubicaje {
  
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 17.09px;
  line-height: 24px;
  color: #1a2228;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

/* Calcular cubicaje */
.calcular-cubicaje {
  width: 174px;
  height: 22px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 15.19px;
  line-height: 21px;
  color: #eceeef;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.mainContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: transparent !important;
  width: 95%;
  margin-left: 2.5%;
  justify-content: center;
}

@media (max-width: 80em) {
  .mainContainer {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0%;
    justify-content: space-between;
    background-color: transparent !important;
  }
}

.aside_card{
  display: flex;
  flex-direction: row;
}
.aside__container{
  padding: 0;
  max-width: 1225px;
  margin: 0 auto 40px;
}
.aside__row{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.aside_card__figure{
  height: inherit;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  width: 118px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex: none;
  
  align-self: stretch;
  flex-grow: 0;
  flex: 0 0 118px;
}
.aside_card__figure img{
  height: auto;
  width: 100px;
}
.aside_card__flex{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 16px;
  width: 100%;
  /* height: 166px; */
  
  order: 1;
  
  
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  width: inherit;
  flex: 1;
}

.aside_card__card-1 {
  background-color: #ffcabf; /* Color de fondo */
}


.card .card-2 {
  order: 1;
}

.aside_card__figure.card-2 {
  background: #bed5c6;
}

/* Frame 36 Color */
.aside_card__flex.card-2 {
  background: #dfeae3;
}
/* Card 4 */
.card .card-4 {
  order: 3;
}

/* Frame 35 Color */
.aside_card__figure.card-4 {
  background: #bbc2c7;
}
.aside_card__card-4 {
  background: #d4d8db;
}

/* Frame 35 Color */
.aside_card__figure.card-3 {
  background: #eceeef;
}

/* Frame 36 Color */
.aside_card__flex.card-3 {
  background: #ffffff;
}
@media (min-width: 768px) {
  .aside__row{
    flex-wrap: wrap;
    flex-direction: row;
  }
  .aside_card{
    flex-grow: 1;
  }
}

@media (min-width: 1024px) {
  .aside_card{
    flex-direction: column;
    width: 48%;
    width: 23%;
  }
  .aside_card__flex,
  .aside_card__figure{
    border-radius: 0;
  }
  .aside_card__flex{
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  
  .aside_card__figure{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: auto;
    height: 128px;
  }
  .aside_card__figure img{
    width: auto;
  }
  .aside_card__flex{
    width: 100%;
  }
}

@media (min-width: 1440px) {
  .aside_card{
    width: 23%;
  }
}