
.exporter-test-information__container {
  margin: 40px auto 0;
  max-width: 1225px;
  width: 100%;
}

.exporter-test__fixed img {
  max-height: 300px;
  width: auto;
  margin: 0 auto;
  display: block;
}

.exporter-test-add-row {
  color: var(--orange-300, #ffa692);
  cursor: pointer;
  font-family: Barlow;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
}

.exporter-test-container-footer {
  align-items: center;
  display: flex;
  margin-bottom: 32px;
  margin-top: 24px;
}

.exporter-test-container-main {
  align-items: center;
  background: var(--gray-800, #2e3c46);
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-right: auto;
  width: 800px;
}
.exporter-test__skip-intro {
  align-items: center;
  background: var(--orange-400, #FF8166);
  border-radius: 20px;
  color: var(--gray-900, #1a2228);
  font-family: Barlow;
  font-size: 0.84375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3125em;
  cursor: pointer;
  display: block;
  margin: 30px auto;
  flex-shrink: 0;
  gap: 10px;
  height: 39px;
  justify-content: center;
  padding: 10px 16px;
  transition: .2s all ease-out;
}
.exporter-test__skip-intro:hover {
  background: #db6e57;
}
.exporter-test__row{
  display: flex;
}
.exporter-test-container-page {
  margin-left: 12%;
}

.exporter-test-header {
  margin-bottom: 34px;
}

.exporter-test-inner {
  margin-top: 2em;
}
.exporter-test-information__footer{
  display: flex;
  justify-content: flex-end;
  
}

.exporter-test-information__btn-start-test {
  align-items: center;
  background: var(--orange-400, #ff8166);
  border-radius: 24px;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 0;
  padding: 12px 20px;
  width: 100%;
  max-width: 200px;
  cursor: pointer;
}

.exporter-test-information__btn-text-start-test {
  color: var(--gray-900, #1a2228);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px;
}

.exporter-test-information__col-4 {
  height: auto;
  max-width: 100%;
}

.exporter-test-information__container-footer {
  align-items: flex-start;
  display: flex;
  margin-bottom: 32px;
  margin-top: 24px;
}

.exporter-test-information__container-main {
  align-items: center;
  background: var(--gray-800, #2e3c46);
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-right: auto;
  padding: 0px 24px;
}

.exporter-test-information__header {
  margin-bottom: 34px;
}

.exporter-test-information__top {
  display: flex;
  gap: 10px;
}
.exporter-test-information__principal-title {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: column;
}

.exporter-test-information__principal-title span:first-child {
  text-align: center;
  color: var(--gray-100, #eceeef);
  font-family: Barlow;
  font-size: 34.64px;
  font-style: normal;
  font-weight: 700;
  line-height: 48.496px;
}

.exporter-test-information__icon-title {
  fill: #eceeef;
  margin: 1.5em 0 0;
  cursor: pointer;
}

.exporter-test-information__percent-bar {
  display: flex;
  margin: 0 0 15px;
}

.exporter-test-information__percent-bar span:first-child {
  padding: 0 11px;
  background: var(--gray-100, #cedfd5);
  color: var(--gray-100, #425563);
  border-radius: 1em;
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px;
}

.exporter-test-information__multi-select {
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-700, #425563);
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px -1px 0px 0px #5e6f7a inset;
  display: flex;
  padding-left: 8px;
}

.exporter-test-information__principal-sub-title {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.926px;
}

.exporter-test-information__question {
  color: #fff4f4;
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.exporter-test-information__sub-title {
  color: #fff;
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px;

}

.exporter-test-information__svg-icon-text-footer {
  height: 24px;
  margin-right: 8px;
  width: 24px;
}

.exporter-test-information__tab-container {
  padding-top: 0.5em !important;
}

.exporter-test-information__textbox-general {
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  border-radius: 0.25em;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  display: flex;
  height: 50px;
  margin-bottom: 0.1em;
  padding-left: 0.5em;
  width: 100%;
  color: #eceeef;
  font-size: 15px;
}
.exporter-test-information__container,
.exporter-test-information__inner{
  width: 100%;
}
input[type="number"],
select {
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  border-radius: 0.25em;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 0.1em;
  padding-left: 0.5em;
  color: #eceeef;
  font-family: inherit;
  font-size: 15px;
}

.exporter-test-information__text-footer {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.926px;
  margin-left: 8px;
}

.exporter-test-information__textbox-arancel {
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  border-radius: 0.25em;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  display: flex;
  height: 3.125em;
  margin-left: 0;
  padding-left: 0.5em;
  width: 183px;
}

.exporter-test-information_fa-trash {
  cursor: pointer;
}
.exporter-test-information__row{
  row-gap: 20px;
}
.exporter-test-information__row + .exporter-test-information__row {
  margin-top: 20px;
}

.exporter-test-information__multi-select {
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-700, #425563);
  box-shadow: 0px -1px 0px 0px #5e6f7a inset;
  padding-left: 8px;
}

.exporter-test__question {
  color: #fff4f4;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.93px;
  margin-bottom: 14px;
  margin-top: 14px;
  max-width: 37em;
}

.exporter-test__tab-container {
  
  padding: 0 20px 20px;
}

.exporter-test__row {
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
}

.exporter-test_textbox-producto {
  align-items: flex-start;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  border-radius: 4px 0px 0px 0px;
  box-shadow: 0px 0.125em 0.25em 0.25em rgba(0, 0, 0, 0.1);
  display: flex;
  height: 3.125em;
  padding-left: 0.5em;
  width: 100%;
  color: #eceeef;
}

.exporter-test_textbox-arancel {
  align-items: flex-start;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  border-radius: 0px 4px 0px 0px;
  box-shadow: 0.25em 0.125em 0.25em 0px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 3.125em;
  padding-left: 0.5em;
  width: 100%;
  margin-left: 0;
  margin-bottom: 0.1em;
  color: #eceeef;
}

.exporter-test_textbox-producto:focus,
.exporter-test_textbox-arancel:focus {
  border-color: #ced4da;
  box-shadow: none;
}

.exporter-test_textbox-producto:focus-visible,
.exporter-test_textbox-arancel:focus-visible {
  outline: none;
}

.exporter-test_textbox-arancel-trash {
  color: #fff4f4;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.93px;
  margin-bottom: 14px;
  margin-top: 14px;
  max-width: 37em;
}

.exporter-test_container-textbox-arancel {
  margin-left: 0;
  flex-grow: 1;
}
.exporter-test_container-textbox-arancel .align-items-center{
  flex-grow: 1;

}

.exporter-test_fa-trash {
  cursor: pointer;
  width: 14px;
  height: 16px;
  margin-left: 22px;
}

.css-13cymwt-control {
  width: 572px;
}

.exporter-test__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.75em;
  background: var(--gray-800, #2e3c46);

  height: auto;
  margin-left: 0;
  width: 100%;
}

.exporter-test__tabs {
  display: flex;
  border-bottom: 1px solid var(--gray-700, #425563);
  padding-left: 0;
  /* width: 51em; */
  height: 60px;
  width: 100%;
  padding: 0 10px 0 0;
}
.tab-test-exporter {
  flex: 1 1;
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 12px 0 7px;
  color: white;
  justify-content: center;
}

.exporter-test__container-footer {
  border-top: 0.0625em solid var(--gray-700, #425563);
  display: flex;
  padding: 1em 2.5em;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  align-self: stretch;
  width: 100%;
  height: 71px;
  align-items: center;
  position: relative;
}

.see_legals {
  margin-top: 0.8em;
  margin-left: -2em;
}

.see_legals p {
  font-family: Barlow;
  font-size: 15.19px;
  font-weight: 600;
  line-height: 21.27px;
  text-align: left;
  cursor: pointer;
  color: #ffcabf;
}

.exporter-test-information__inner {
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.exporter-test-information__dropdown-general {
  display: flex;
  padding-left: 0.5em;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.25em;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 50px;
  margin-bottom: 0;
  color: var(--gray-300, #bbc2c7);
  cursor: pointer;
}

.exporter-test-information__dropdown-general option {
  cursor: pointer;
  color: var(--gray-100, #eceeef);
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px; /* 140% */
}
.exporter-test__notificacion {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.exporter-test-information__dropdown-general option:not(:checked) {
  color: var(--gray-300, #bbc2c7);
}

.exporter-test__dropdown-general-full {
  display: flex;
  padding-left: 0.5em;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.25em;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 50px;
  margin-top: 12px;
  margin-bottom: 12px;
  color: var(--gray-300, #bbc2c7);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.49875em;
  align-items: center;
}

.exporter-test__dropdown-general-middle {
  display: flex;
  padding-left: 0.5em;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.25em;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 3.3em;
  color: var(--gray-300, #bbc2c7);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.49875em;
  align-items: center;
}

.exporter-test__label {
  color: var(--gray-300, #bbc2c7);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px;
  margin: 0 0;
}

.animationTest__container {
  max-width: 600px;
  /* Ancho máximo del contenedor */
  margin: 0 auto;
  /* Centrar el contenedor en la página */
  padding: 20px;
  /* Espaciado interior del contenedor */
  text-align: left;
  /* Alineación del texto a la izquierda */
  border-radius: 12px;
  background: var(--gray-800, #2e3c46);
}

.animationTest__title {
  display: block;
  /* Mostrar el título como un bloque */
  font-size: 24px;
  /* Tamaño de fuente del título */
  margin-bottom: 10px;
  /* Espacio inferior entre el título y la descripción */
}

.animationTest__p {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.926px;
  /* 140% */
}

button {
  font-size: 16px;
  /* Tamaño de fuente del botón */
  cursor: pointer;
  /* Cambiar el cursor al pasar por encima del botón */
}

button span {
  margin-left: 5px;
  /* Espacio a la izquierda del icono de página */
}

.animationTest__title {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 19.22px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px;
  /* 124.485% */
}

.exporter-test-information__container-page {
  width: 80%;
  margin-left: 8vw;
}

.animationTest__image-container {
  margin-top: 20px;
}

.animationTest__image-container img {
  max-width: 100%;
  /* Asegurarse de que la imagen no exceda el ancho del contenedor */
  height: auto;
  /* Mantener la proporción de aspecto de la imagen */
  display: block;
  /* Evitar el espacio adicional debajo de la imagen */
  margin: 0 auto;
  /* Centrar la imagen dentro de su contenedor */
}

.animationTest__next {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 1.25em;
  background: var(--green-500, #adcab8);
  cursor: pointer;
  display: flex;
  padding: 0.625em 1em;
  justify-content: center;
  align-items: center;
  gap: 0.625em;
  position: absolute;
}

.animationTest__next:hover {
  border-radius: 1.25em;
  background: var(--green-400, #bed5c6);
  cursor: pointer;
}

.animationTest__next-text {
  margin-right: 5px;
  color: var(--gray-900, #1a2228);
  font-family: Barlow;
  font-size: 0.84375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3125em;
}

.animationTest__container-footer {
  border-top: 0.0625em solid var(--gray-700, #425563);
  display: flex;
  justify-content: flex-start;
  /* Alinea los elementos a la izquierda */
  align-items: center;
  gap: 1em;
  width: 100%;
  height: 71px;
  padding: 0 2.5em;
  /* Ajusta el padding según tus necesidades */
  position: relative;
  /* Ajusta la posición según tus necesidades */
}

.exporter-test__next {
  display: flex;
  padding: 0.625em 1em;
  justify-content: center;
  align-items: center;
  gap: 0.625em;
  position: absolute;
  left: 87%;
  transform: translateX(-50%);
  cursor: pointer;
  margin-right: 0.625em;
  cursor: pointer;
  border-radius: 1.25em;
  background: var(--green-500, #adcab8);
}

.exporter-test-information__error-container {
  opacity: 0;
  display: flex;
  align-items: center;
  display: none;
}

.exporter-test-information__error-container.show {
  opacity: 1;
  display: flex;
}

.exporter-test-information__error-message {
  color: #f8a0d2;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

/* .exporter-test_textbox-arancel::placeholder,
.textbox-tel::placeholder,
.exporter-test-information__textbox-general::placeholder  */

.exporterTest__flex input::placeholder{
  color: #BBC2C7;
}
.textbox-tel{
  font-size: 15px;
}
.exporter-test__select-font {
  color: #bbc2c7 ;
  font-family: Barlow ;
  font-size: 15px ;
  font-style: normal;
  font-weight: 400;
  line-height: 21.266px;
  cursor: pointer;
  padding-top: 13px;
}

.errorContainer {
  align-items: center;
  margin-top: 6px;
  margin-left: -10px;
}

.errorInputValidation {
  font-family: Barlow;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  color: #f8a0d2;
  margin: 0;
  margin-left: 8px;
}



@media (min-width: 768px) {
  .exporter-test-information__principal-title{
    flex-direction: row;
  }
  .exporter-test-information__percent-bar{
    margin: 13px 0 10px 16px;
  }
  .exporter-test-information__percent-bar span:first-child{
    padding: 3px 11px;
  }

  .tab-test-exporter {
    font-size: 15px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-bottom: 0.1875em solid #2E3C46;
    cursor: pointer;
    min-width: 5em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0.4375em;
    margin-right: 0.4375em;
    flex: inherit;
    gap: 0px;
    flex-grow: 1;
  }
  
  
}

@media (min-width: 1024px) {
  .exporter-test__row {
    flex-direction: row;
    gap: 0;
  }
  
  .exporter-test__flex {
    flex: 0 0 auto;
    width: 66.66666667%;
    padding-right: 30px;
  }

  .exporter-test__fixed {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .exporter-test-information__container-footer{
    align-items: center;
  }
    
  
}

@media (min-width: 1440px) {
  .exporter-test__flex{
    padding-right: 70px;
  }
}

