.globo_mensaje_cubicaje{
    /* background-color: #2ECADF; */
    padding: 5px;
    margin-left: 15px;
  
    span {
      color: black;
      font-weight: bold;
      font-size: 13px;
    }
  }

  .globo_mensaje_custom-message {
    padding: 8px 12px;
    margin-left: 15px;
  
    span {
      color: black;
      font-size: 13px;
    }
  }
  
  .custom-message__text {
    color: var(--gray-200, #d4d8db);
    font-family: Barlow;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.926px;
    margin-left: 8px;
  }