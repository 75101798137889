.container-test-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.75em;
  background: var(--gray-800, #2E3C46);
  max-width: 800px;
  height: auto;
}

.tabs {
  display: flex;
  padding-right: 1em;
  align-items: contents;
  max-height: 3.75em;
  margin-top: 1em;
}

.tab-test-profile {
  display: flex;
  align-items: center;
  width: auto;
  flex-direction: row ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75em 1.25em;
  border-bottom: 0.1875em solid #2E3C46;
  cursor: pointer;
  min-width: 5em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0.4375em;
  margin-right: 0.4375em;
}

.tab-container {
  width: 100%;
  padding: 20px;
  height: auto;
}

.circle {
  border-radius: 1em;
  background: var(--gray-300, #BBC2C7);
  display: flex;
  width: 19px;
  height: 19px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625em;
  
  color: var(--gray-700, #425563);
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.49875em;
}

.circle-active {
  background: var(--orange-400, #FF8166);
  color: var(--gray-900, #1A2228);
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.49875em;
}

.text-test-profile {
  color: var(--gray-300, #BBC2C7);
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.49875em;
}

.text-test-profile-active {
  color: #ECEEEF;
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.49875em;
}

.rowPanel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
}

.titleRow {
  color: #FFF4F4;
  font-family: Raleway;

  font-style: normal;
  font-weight: 700;

  font-size: 20px;
  line-height: 24px;
  margin: 0 0 10px;
}


.textbox {
  margin-right: 0.625em;
}

.phoneRow {
  display: flex;
  align-items: center;
  margin-right: 0.625em;
}

.tab-test-profile {
  align-items: center;
  color: #fff;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  font-size: 12px;
  gap: 2px;
  justify-content: center;
  padding: 12px 0 7px;
  text-align: center;
  margin: 0;
}

.tabs {
  border-bottom: 1px solid #425563;
  border-bottom: 1px solid var(--gray-700, #425563);
  display: flex;
  height: 60px;
  padding: 0 10px 0 0;
  width: 100%;
  margin: 0;
}

.textbox-general {
  display: flex;
  padding-left: 0.5em;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.25em;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 3.125em;
  margin-bottom: 0.75em;
}

.PhoneInputInput {
  background: var(--gray-700, #425563) !important;
  border: none;
  color: #FFF4F4;
}

.PhoneInputInput--focus {
  border: none !important;
}

.PhoneInputCountry {
  color: #FFF4F4 !important;
  font-weight: 600;
}

.react-flags-select .flag-select__arrow:before {
  border-color: transparent transparent white !important;
}

.textbox-general {
  margin-bottom: 0;
}

.PhoneInputInput {
  margin-bottom: 0;
}

.dropdown-general {
  display: flex;
  padding-left: 0.5em;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.25em;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 3.3em;
  margin-bottom: 0;
  color: var(--gray-300, #BBC2C7);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.49875em;
  align-items: center;
}

.dropdown-general select {
  color: var(--gray-300, #BBC2C7);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.49875em;
}

.dropdown-general option {
  color: var(--gray-300, #BBC2C7);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.49875em;
}

.btn-footer {
  border-top: 0.0625em solid var(--gray-700, #425563);
  display: flex;
  padding: 1em 2.5em;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  align-self: stretch;
  width: 100%;
  height: 4.4375em;
}

.btn-next-tp {
  border-radius: 1.25em;
  background: var(--green-500, #ADCAB8);
  display: flex;
  width: 7.1875em;
  height: 2.4375em;
  padding: 0.625em 1em;
  justify-content: center;
  align-items: center;
  gap: 0.625em;
  color: var(--gray-900, #1A2228);
  font-family: Barlow;
  font-size: 0.84375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3125em;
}

.tab-next {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75em 1.25em;
  border-bottom: 0.1875em solid #2E3C46;
  cursor: pointer;
  min-width: 5em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0.4375em;
  margin-right: 0.4375em;
}

.btn-test-next {
  display: flex;
  padding: 0.625em 1em;
  justify-content: center;
  align-items: center;
  gap: 0.625em;
  position: absolute;
  right: 20px;

  border-radius: 1.25em;
  background: var(--green-500, #ADCAB8);
  cursor: pointer;

}

.btn-test-next:hover {
  border-radius: 1.25em;
  background: var(--green-400, #BED5C6);
  cursor: pointer;
}

.btn-test-next-text {
  color: var(--gray-900, #1A2228);
  font-family: Barlow;
  font-size: 0.84375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3125em;
}

.avatar-img {
  display: flex;
  width: 8.25em;
  height: 8.25em;
  align-items: center;
}

.avatar-img-item {
  border-radius: 9.25em;
  margin-right: 2.5em;
  cursor: pointer;
}

.avatar-img-item-active {
  border: 0.5em solid #BED5C6;
  border-radius: 50%;
}

.panels {
  display: flex;
  flex-wrap: wrap;
  max-width: 45em;
}

.panel-notification {
  display: flex;
  
  padding: 1.5em 1.1875em;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  flex: 1 0 0;
  border-radius: 0.5em;
  background: var(--gray-700, #425563);
  width: 100%;
  height: 7.375em;
  margin-right: 0;
}

.calendar-img {
  width: 1.75em;
  height: 1.6893125em;
  flex-shrink: 0;
  border-radius: 0.2333em;
  background: var(--green-100, #EFF4F1);
}

.panel-notification-active {
  background: var(--green-400, #BED5C6);
  color: #1A2228;
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.49875em;
}

.txt-config-active {
  color: #1A2228;
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.49875em;
}

.txt-config {
  color: var(--green-200, #DFEAE3);
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.49875em;
}

.custom-checkbox-label {
  position: relative;
  padding-left: 1.5625em;
  cursor: pointer;
  user-select: none;
  margin-left: 15px;
}

.custom-checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox-indicator {
  position: absolute;
  width: 1.25em;
  height: 1.25em;
  border-radius: 0.125em;
  border: 0.0625em solid #ECEEEF;
  left: 0;
}

.custom-checkbox-input:checked + .custom-checkbox-indicator::after {
  content: '';
  position: absolute;
  top: 0.3125em;
  left: 0.3125em;
  width: 0.625em;
  height: 0.625em;
  border-left: 0.125em solid transparent;
  border-bottom: 0.125em solid transparent;
  border-right: 0.125em solid #ECEEEF;
  border-top: 0.125em solid #ECEEEF;
  transform: rotate(45deg);
}

.custom-checkbox-label {
  color: #ECEEEF;
  font-family: Barlow;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 1.5em;
  padding-left: 2em;
}

.textbox-tel,
.textbox-general {
  color: #BBC2C7;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Barlow;
  font-size: 0.949375em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.49875em;
}

.textbox-tel {
  width: 250px I !important; 
}

.text-profile__row {
  margin-bottom: 0.8em;
  row-gap: 12px;
}

@media (min-width: 1024px) {
  .tab-test-profile {
    align-items: center;
    border-bottom: .1875em solid #2e3c46;
    cursor: pointer;
    display: flex;
    flex: inherit;
    flex-direction: row !important;
    flex-direction: column;
    font-size: 15px;
    gap: 12px;
    justify-content: center;
    margin: 1em .4375em;
    min-width: 5em;
    padding: .75em 1.25em;
    width: auto;
  }

  .circle {
    width: 24px;
    height: 24px;
  }

  .text-test-profile {
    font-size: 15px;
  }
  .rowPanel{
    flex-direction: row;
  }
  .panel-notification{
    height: 112px;
  }
  .titleRow{
    margin: 10px 0 25px;
  }
}