.custom-country-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-country-title {
  margin-bottom: 8px;
  /* Espacio entre el título y el resto del contenido */
  color: #9ea8af;
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 100% */
  letter-spacing: 0.018px;
  margin-left: 0.5em;
  padding-top: 0.2em;
}

.country-info {
  display: flex;
  align-items: center;

}

.country-code {
  margin-left: 8px;
  /* Espacio entre la bandera y el código del país */
  color: var(--gray-100, #ECEEEF);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.266px;
  /* 140% */
  letter-spacing: 0.25px;
  margin-bottom: 1.5em;
}

.expand-icon {
  margin-left: 8px;
  /* Espacio entre el código del país y el icono de expansión */
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  background: var(--gray-700, #425563) !important;
  color: #BBC2C7;
  margin-top: -0.4em;
  border: 0;
  padding: 0px;
}

.ReactFlagsSelect-module_selectBtn__19wW7:after {
  border-top: 5px solid white !important;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  position: relative;
  vertical-align: inherit;
  padding-bottom: 1em;
  text-align: left;
  padding-left: 0.5em;
}

.textbox-country-indicator {
  padding-left: 1px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  background: var(--gray-700, #425563);
  box-shadow: 0px -1px 0px 0px #5E6F7A inset;
  width: 5.5em;
  height: 50px;
  margin-bottom: 0;
  position: relative;
  z-index: 2;
  margin-top: 0;
}

.ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
  width: 15em;
  ;
}

.PhoneInputInput {
  color: #9ea8af;
  width: 40%;
  margin-top: -0.25em;
}

.PhoneInputInput:focus-visible {
  outline: none;
}

.PhoneInputCountry {
  margin-left: 0.5em;
}

.textbox-tel {
  border-radius: 4px 4px 0px 0px;
  background: var(--gray-700, #425563);
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 50px;
  margin-bottom: 0;
}