.topContentMainContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
}
.topDataContainer {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
}


.exporter-test__notificacion {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.finalTest__fixed img {
  max-width: 220px;
  margin: 20px auto 40px;
  display: block;
}

.mainSquareContainer {
  padding: 20px;
  background-color: #2e3c46;
  border-radius: 12px;
}

.topDataContainer {
  justify-content: space-between;
}

.subtitleText {
  color: #bed5c6;
  font-family: Barlow;
  font-size: 13px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.actualLevelTitleText {
  font-family: Raleway;
  
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
  margin-top: 0px;
}

.characteristicsContainer {
  margin-top: 25px;
  margin-bottom: 32px;
}

.characteristicsLabelTitle {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
}

.characteristicsItem {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.circleItem {
  background-color: #ff8166;
  width: 8px;
  height: 8px;
  border-radius: 100px;
}

.characteristicsItem p {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
  margin: 0;
  margin-left: 8px;
}

.summaryContainer {
  background-color: #cedfd5;
  padding: 15px 20px  ;
  border-radius: 12px;
  margin-bottom: 20px;
  width: 100%;
  margin-left: 0;
}

.summaryContainer p {
  font-family: Barlow;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #1a2228;
  align-self: center;
  margin: 0;
}

.descriptionContainer {
  margin-bottom: 32px;
}

.descriptionContainer p {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
}

.levelImage {
  display: flex;
  align-self: self-start;
  max-width: 65px;
  margin: 0;
  height: auto;

}

.divider {
  border-top: 1px solid #425563;
}

.footerSquareContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.downloadText {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffcabf;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
}

.editFormButton {
  background-color: #ff8166;
  color: #1A2228;
  padding: 10px 16px 10px 16px;
  border-radius: 20px;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

@media (min-width: 768px) {}

@media (min-width: 1024px) {
  .topContentMainContainer {      
      flex-direction: row;
      margin: 40px auto 0;
      max-width: 1225px;
      width: 100%;
  }
  .finalTest__flex.mainSquareContainer {
      flex: 0 0 auto;
      width: 66.66666667%;
      padding-right: 30px;
  }
  .finalTest__fixed {
      flex: 0 0 auto;
      width: 33.33333333%;
  }
  .topDataContainer{
    flex-direction: row;
  }
  .levelImage{
    max-width: inherit;
  }
  .subtitleText {
    font-size: 15px;
    line-height: 21px;
  }
  .actualLevelTitleText{
    font-size: 35px;
    line-height: 48px;
  }
  .characteristicsItem {
    font-size: 17px;
    line-height: 24px;
  }
  .summaryContainer {
    padding: 15px 35px;
    margin-bottom: 32px;
  }
  .summaryContainer p{
    font-size: 19px;
    line-height: 26px;
  }
  .descriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .descriptionContainer p {
    margin: 0;
  }

}