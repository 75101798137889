.CustomSwitch__LeftOption {
    width: 51px ;
    height: 30px ;
    gap: 10px  ;
    display: flex  ;
    padding: 4px 16px  ;
    align-items: flex-start  ;
    background-color: #425563 ;
    border-radius: 16px 0px 0px 16px !important; 
}

.CustomSwitch__CenterOption {
    width: 51px ;
    height: 30px ;
    gap: 10px  ;
    display: flex  ;
    padding: 4px 16px  ;
    align-items: flex-start  ;
    background-color: #425563 ;
}


.CustomSwitch__RightOption {
    width: 72px ;
    height: 30px ;
    gap: 10px  ;
    display: flex  ;
    padding: 4px 16px  ;
    align-items: flex-start  ;
    background-color: #425563 ;
    border-radius: 0px 16px 16px 0px !important; 
}

.CustomSwitch__Font  {
    color: var(--gray-300, #BBC2C7);
    font-family: Barlow  !important;
    font-size: 14px  !important;
    font-style: normal  !important;
    font-weight: 400  !important;
    line-height: 21.266px   !important;
}

.CustomSwitch__NoVisible {
    display: none !important;
} 