


.suggest {
  align-items: flex-start;
  background-color: #2e3c46;
  border-radius: 12px;
  box-sizing: border-box;
  filter: drop-shadow(0 4px 24px rgba(26, 34, 40, .2));
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

.suggest__row {
  display: flex;
  /* max-width: 447px; */
  /* padding: 0px 20px 24px 20px; */
  padding: 0px 20px 75px 20px;
  flex-direction: column;
  align-items: center;
  flex: 1 0;
  flex-grow: 1;
  position: relative;
  /* min-height: 32em; */
  height: auto;
  max-width: 100%;
  width: 100%;
}
.suggest__item {
  width: 100%;
}
.suggest__header {
  display: flex;
  padding: 24px 0px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.suggest__header__title {
  color: var(--green-300, #CEDFD5);
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0;
}
.suggest__body {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.suggest__body__title {
  color: #FFF;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; 
  margin: 0;
}

.suggest__body__text {
  color: var(--gray-200, #D4D8DB);
  font-family: Barlow;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.9px;
  /* min-width: 25em; */
  margin: 0;
}
.suggest__footer {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  margin: 18px 0 0;
}
.suggest__date {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #ADCAB8;
}

.suggest__actions {
  width: 40px;
  text-align: center;
}

.suggest__save {
  fill: white;
}

.suggest__more {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  transform: translateX(-50%);
  border-radius: 20px;
  background: var(--green-500, #ADCAB8);
  cursor: pointer;
  bottom: 20px;
  left: 50%;
  width: 15em;
}


.suggest__more:hover {
  border-radius: 20px;
  background: var(--green-400, #BED5C6);
  cursor: pointer;
}

.suggest__more__text {
  color: var(--gray-900, #1A2228);
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
}

@media (min-width: 1024px) {
  .suggest__row {
    min-height: 32em;
    padding: 0px 20px 24px 20px;  
  }
}