.container {
  position: relative;
}

.register-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 320px; 
  border-radius: 0.75em; 
  background-color: #2E3C46;
  gap: 1.5em; 
  padding: 0 20px 20px;
  user-select: none;
  height: auto;
  /* margin-top: -0.0625em;  */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rPassword-loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.txt-user-rp,
.txt-alias-rp,
.txt-pass-rp {
  width: 100%;
  height: 3.125em;
  background-color: #5E6F7A;
  color: #BBC2C7;
  font-family: Barlow;
  font-size: 16px;
  border-radius: 0.25em 0.25em 0em 0em;
  box-shadow: 0em -0.0625em 0em 0em #5E6F7A inset;
  margin-bottom: 0.5em;
  padding-left: 0.5em;
}

.txt-user-rp::placeholder,
.txt-alias-rp::placeholder,
.txt-pass-rp::placeholder {
  color: #BBC2C7;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.32813em;
  font-feature-settings: 'clig' off, 'liga' off;
}

.register-title {
  font-family: Raleway;
  font-size: 20px;
  color: #ECEEEF;
  font-weight: 700;
  margin-bottom: 1.5em; /* Cambio de 24px a 1.5em */
}

.register-btn {
  width: 100%; 
  height: 2.875em; /* Cambio de 46px a 2.875em */
  display: flex;
  padding: 0.75em 1.25em; /* Cambio de 12px 20px a 0.75em 1.25em */
  justify-content: center;
  align-items: center;
  gap: 0.625em; /* Cambio de 10px a 0.625em */
  align-self: stretch;
  border-radius: 1.5em; /* Cambio de 24px a 1.5em */
  background: #FF8166;
  margin-bottom: 1.5em; /* Cambio de 24px a 1.5em */
  margin-top: 1em; /* Cambio de 16px a 1em */
}

.register-btn-text {
  color: #1A2228;
  font-size: 0.949em; /* Cambio de 15.19px a 0.949em */
  font-weight: 600;
}

.register-image {
  margin-bottom: 0.5em; /* Cambio de 8px a 0.5em */
  position: relative;
  margin-left: -0.0625em; /* Cambio de -1em a -0.0625em */
  width: 80px;
}

.txt-recover-password {
  color: #FFCABF;
  font-family: Barlow;
  font-size: 0.949em; /* Cambio de 15.19px a 0.949em */
  font-style: normal;
  font-weight: 600;
  line-height: 1.328em; /* Cambio de 21.266px a 1.328em */
  margin-top: 0.5em; /* Cambio de 8px a 0.5em */
  margin-bottom: 1.5em; /* Cambio de 24px a 1.5em */
}

.normal-text {
  color: #ECEEEF;
  font-family: Barlow;
  font-size: 0.949em; /* Cambio de 15.19px a 0.949em */
  font-style: normal;
  font-weight: 400;
  line-height: 1.328em; /* Cambio de 21.266px a 1.328em */
  margin-right: 0.5em; /* Cambio de 8px a 0.5em */
}

.password-input-container {
  position: relative;
  display: inline-block;
}

.toggle-password-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.6875em; /* Cambio de 1.1em a 0.6875em */
  width: 1.125em; /* Cambio de 18px a 1.125em */
  height: 1em; /* Cambio de 16px a 1em */
  cursor: pointer;
  color: #ECEEEF;
}

.toggle-password-icon-container {
  position: absolute;
  top: 50%;
  right: 0; /* Ajustar según sea necesario */
  transform: translateY(-50%);
}


.toggle-password-icon-active {
  margin-top: -1.2em;
}

.error-container {
  display: flex;
  align-items: center;
  margin-bottom: 1em; /* Cambio de 16px a 1em */
}

.error-message {
  margin-left: 0.5em; /* Cambio de 8px a 0.5em */
  color: #F8A0D2;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Barlow;
  font-size: 13px; /* Cambio de 12px a 0.75em */
  font-style: normal;
  font-weight: 400;
  line-height: 0.75em; /* 100% */
}

.txt-success {
  border-bottom-width: 0.125em; /* Cambio de 2px a 0.125em */
  border-radius: 0.25em; /* Cambio de 4px a 0.25em */
  background: var(--gray-700, #425563);
  box-shadow: 0px -0.125em 0px 0px #ADCAB8 inset; /* Cambio de 0px -2px 0px 0px #ADCAB8 inset a 0px -0.125em 0px 0px #ADCAB8 inset */
}

.txt-error {
  border-bottom-width: 0.125em; /* Cambio de 2px a 0.125em */
  border-radius: 0.25em; /* Cambio de 4px a 0.25em */
  background: var(--gray-700, #425563);
  box-shadow: 0px -0.125em 0px 0px #F8A0D2 inset; /* Cambio de 0px -2px 0px 0px #F8A0D2 inset a 0px -0.125em 0px 0px #F8A0D2 inset */
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}


@media (min-width: 768px) {
  .register-container{
    padding: 20px 40px 40px;
    width: 440px;
  }
  .register-title{
    font-size: 22px;
  }
  .register-image{
    width: initial;
  }
}