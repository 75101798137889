.css-13cymwt-control {
    display: flex !important;
    padding-left: 8px !important;
    align-items: flex-start !important;
    align-self: stretch !important;
    border: 0.0625em solid transparent !important;
    max-width: 42em !important;

}

.css-kbib62-control {
    border-radius: 0.25em !important;
    box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1) !important;
    border-color: var(--gray-700, #425563) !important;
    background-color: var(--gray-700, #425563) !important;
}

.css-1p3m7a8-multiValue {
    display: flex !important;
    padding: 4px 12px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    border-radius: 8px !important;
    background: var(--gray-800, #2E3C46) !important;
}

.css-12a83d4-MultiValueRemove {
    width: 12px;
    height: 12px;
}

.css-wsp0cs-MultiValueGeneric {
    color: var(--gray-200, #D4D8DB) !important;
    font-family: Barlow !important;
    font-size: 13.5px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18.9px !important;
}

.close-icon-container {
    margin-left: 0.5em;
}

.close-icon {
    width: 1.1em;
    height: 1.1em;
    fill: #000;
    margin-left: -0.5em;
    margin-top: 0;
}

.close-icon:hover {
    background-color: #2E3C46;
}


.css-1nmdiq5-menu {
    background: var(--gray-800, #2E3C46) !important;
    color: var(--gray-100, #ECEEEF) !important;
    font-family: Barlow !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 19.6px !important;
}

.css-myo0yd-MultiValueRemove {
    background-color: #2E3C46 !important;
}

.css-myo0yd-MultiValueRemove:hover {
    background-color: #2E3C46 !important;
}

.css-t3ipsp-control {
    background-color: #5E6F7A !important;
}

.css-tj5bde-Svg {
    color: #BBC2C7 !important;
}

/* Estilo para la opción seleccionada */
.css-kjr00u-multiValue {
    border-radius: 8px;

}

.css-599w6d-MultiValueRemove {
    border-radius: 8px;
}

.css-uyxosn-control {
    height: auto !important;
}

.css-3w2yfm-ValueContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    overflow-y: auto;
}

.css-9jq23d  {
    color: #D4D8DB !important;
}

.css-1coe76s-multiValue {
    border-radius: 10px;
    height: 2em;
    align-items: center;
}

.css-149wadv {
    border-radius: 10px;
}