.terminosServicioContainer{
  flex-direction: row;
  justify-content: space-between;
  max-width: 1216px;
  margin: 20px auto 0;
}
.terminosServicioContainer_top{
  display: flex;
  flex-direction: column-reverse;
}
.terminosServicioContainer .btn{
  text-align: left;
}
.terminosServicioContainer_top img{
  max-width: 150px;
  margin: 20px 0 40px;
}
.terminosServicio-information__principal-title p {
  margin: 20px 0 0;
}
.terminosServicioContainer .card-header-false h5 span {
  text-align: left;
  margin-left: 0 ;
}
.terminosServicioContainer .card{
  margin-right: 0;
}
.terminosServicioContainer .row{
  position: relative;
}
.terminosServicio-information__arrow {
  position: absolute;
  right: 20px;
  top: 0px;
  width: auto;
}
.terminosServicioContainer .card-header {
  padding-left: 8px;
}
.terminosServicioContainer .btn{
  padding-left: 5px;
  padding-right: 20px;
}
.terminosServicioContainer .card-body{
  padding-top: 0 ;
  font-size: 14px;
  margin-left: 0;
}
.card-body{
  margin-left: 0;
}

.btn{
  font-weight: bold;
  size: 17px
}

.collapse:not(.show) {
  width: 1250px !important;
}

.acordeonTabs{
  width: 1250px !important;
  
}

.card{
  max-width: 100% !important;
  background-color: #CEDFD5;
  color: black;
  width: auto !important;
  margin-bottom: 15px;
}

.card-false{
  max-width: 100% !important;
  background-color: rgba(46, 60, 70, 0.94);
  color: white;
  width: auto !important;
  margin-bottom: 15px;
  border-radius: 0.375rem;
}

.card-header-false{
  max-width: 100% !important;
  background-color: rgba(46, 60, 70, 0.94);
  border-bottom: none !important;
  padding: 0.5rem;

  h5 span{
    color: white !important;
    margin-left: 9px;
  }
}

.card-header{
  max-width: 100% !important;
  background-color: #CEDFD5;
  color: black;
  border-bottom: none !important;
}

.terminosServicio-information__principal-title span:first-child {
  text-align: center;
  color: var(--gray-100, #eceeef);
  font-family: Barlow;
  font-size: 34.64px;
  font-style: normal;
  font-weight: 700;
  line-height: 48.496px;
}

.terminosServicio-information__principal-title p {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 140% */
}

.container-news-sgg {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.content-news-sgg {
  display: flex;
  max-width: auto;
  margin-top: 1em;
}

.main {
  flex-grow: 1;
  flex-basis: auto;
  width: 46.5625em;
  margin-right: 1.2em;
}

.suggestions {
  flex-shrink: 0;
  flex-basis: auto;
  width: 25.4375em;
}

.dark-overlay:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8888;
}

.activate-overlay {
  position: relative;
  z-index: 9999;
}


@media (min-width: 1024px) {
  .terminosServicioContainer_top {
      flex-direction: row;
      margin-top: 40px;
  }
  .terminosServicioContainer_top .col-lg-5{
    text-align: center;
  }
  .terminosServicioContainer_top img{
    max-width: inherit;
  }
  
  .terminosServicioContainer .card-body{
    font-size: 15px;
  }
  .terminosServicioContainer .card-header {
    padding-left: 23px;
}

.terminosServicioContainer .card-header-false h5 span {
    padding-left: 20px;
}

.terminosServicioContainer .card-body {
    
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-top: 10px;
}
}