.mod_preferences{
  max-width: 1226px;
  margin: 30px auto 0;
  width: 100%;
}
.mod_preferences__header{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.preferences__principal-title {
  color: var(--gray-100, #eceeef);
  font-family: Raleway;
  font-size: 34.64px;
  line-height: 48.496px;
  font-size: 28px;
  line-height: 42px;
  font-style: normal;
  font-weight: 700;
  display: block;
}

.preferences__principal-subtitle {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 17.09px;
  line-height: 23.926px;
  font-size: 15px;
  line-height: 21px;
  font-style: normal;
  font-weight: 400;
  display: block;
  margin-bottom: 0;
  
}

.preferences__container-information {
  border-radius: 12px;
  background: var(--gray-800, #2e3c46);
  width: 100%;
  /* height: 430px; */
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.preferences__btn-text {
  display: inline-flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: var(--green-500, #adcab8);
  margin-top: 2em;
  color: var(--gray-900, #1a2228);
  font-family: Barlow;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.9px;
  margin-bottom: 2em;
  transition: .2s all ease-out;
}
.preferences__btn-text:hover{
  background: #899e91;
}

.preferences__centered-button {
  display: flex;
  justify-content: center;
  margin-top: 0;
}

.preferences__btn-exit {
  height: 39px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: var(--gray-400, #97a2a9);
  margin-right: 1em;
  color: var(--gray-900, #1a2228);
  font-family: Barlow;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.9px;
  border: none;
  /* 140% */
  transition: .2s all ease-out;
}
.preferences__btn-exit:hover{
  background: #7a848b;
}

.preferences__btn-save {
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: var(--orange-400, #ff8166);
  color: var(--gray-900, #1a2228);
  font-family: Barlow;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.9px; /* 140% */
  border: none;
  transition: .2s all ease-out;
}
.preferences__btn-save:hover {
  background: #dd6c53;
}

.preferences__button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
  margin-right: 1em;
  margin-top: 1em;
}

.topMainPreferencesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.buttonsPreferencesActionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  gap: 20px;
}

.preferences__action {
  background-color: #425563;
  border-radius: 8px;
  width: 80px;
  align-items: center;
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 60px;
  padding-top: 20px;
  font-size: 13px;
  gap: 5px;
  color: #ffffff;
  transition: .2s all ease-out;
}
.preferences__action:hover{
  background-color: #bed5c6;
  color: #1a2228;
}
.preferences__action:hover img{
  filter: invert(1);
}

.preferences__action img{
  max-width: 20px;
}
.preferences__action p {
  
  margin-top: 0;
}

@media (min-width: 1024px) {

  .preferences__principal-title {
    font-size: 34.64px;
    line-height: 48.496px;
  }

  .preferences__principal-subtitle {
    font-size: 17.09px;
    line-height: 23.926px;
  }

  .mod_preferences{
    margin-top: 50px;
  }
  .mod_preferences__header {
    gap: 12px;
  }
  .topMainPreferencesContainer{
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 27px;
  }
  .buttonsPreferencesActionContainer{
    gap: 16px;
  }
  .preferences__action {
    height: 82px;
    font-size: 15px;
    width: 118px;
  }
  .preferences__action img {
    max-width: inherit;
  }
  .preferences__centered-button{
    margin-top: 0;
  }
}