.app__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button {
  
  all: unset; 
  box-sizing: border-box; 
  display: inline-block; 
  cursor: pointer; 
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body input[type="text"],
body input[type="password"],
body textarea {
  border-width: 0;
}

.error-message-validation {
  margin-left: 0.5em; /* Cambio de 8px a 0.5em */
  color: #F8A0D2;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Barlow;
  font-size: 13px; /* Cambio de 12px a 0.75em */
  font-style: normal;
  font-weight: 400;
  line-height: 0.75em; /* 100% */
  margin-top: 8px;
}
