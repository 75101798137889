.container {
  position: relative;
  user-select: none;
}

.login-container {
  position: absolute; /* o 'fixed' según tus necesidades */
  top: 50%; /* Posiciona el borde superior del elemento en la mitad de la pantalla */
  left: 50%; /* Posiciona el borde izquierdo del elemento en la mitad de la pantalla */
  transform: translate(-50%, -50%); /* Centra el elemento horizontal y verticalmente */
  width: 320px;
  padding: 20px;
  border-radius: 0.75em;
  background-color: #2E3C46;
  gap: 1.5em;
  user-select: none;
}

.txt-user,
.txt-pass {
  width: 100%;
  height: 3.125em;
  background-color: #5E6F7A;
  color: #BBC2C7;
  font-family: Barlow;
  font-size: 16px;
  padding-left: 0.5em;
  border-radius: 0.25em 0.25em 0em 0em;
  box-shadow: 0em -0.0625em 0em 0em #5E6F7A inset;
  margin-bottom: 0.5em;
}

.txt-user::placeholder,
.txt-pass::placeholder {
  color: #BBC2C7;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}


.login-title {
  font-family: Raleway;
  font-size: 1.35em;
  color: #ECEEEF;
  font-weight: 700;
  margin-bottom: 1.5em;
  user-select: none;
}

.login-btn {
  width: 100%;
  height: 2.875em;
  display: flex;
  padding: 0.75em 1.25em;
  justify-content: center;
  align-items: center;
  gap: 0.625em;
  align-self: stretch;
  border-radius: 1.5em;
  background: #FF8166;
  margin-bottom: 1.5em;
}

.login-btn-text {
  color: #1A2228;
  font-size: 0.95em;
  font-weight: 600;
  margin-top: 1.25em;
}

.login-image {
  margin-bottom: 1.5em;
  position: relative; 
  margin-left: -0.0625em;
}

.txt-recover-password {
  color: #FFCABF;
  font-family: Barlow;
  font-size: 0.95em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.32813em;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}

.normal-text {
  color: #ECEEEF;
  font-family: Barlow;
  font-size: 0.95em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.32813em;
  margin-right: 0.5em;
}

.password-input-container {
  position: relative;
  width: 100%;
  display: inline-block;
}

.toggle-password-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.6875em;
  width: 1.125em;
  height: 1em;
  cursor: pointer;
  color: #ECEEEF;
}


@media (min-width: 768px) {
  .login-container{
    width: 440px;
    padding: 30px 40px;
  }
}