.container-news-sgg {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.content-news-sgg {
  display: flex;
  max-width: auto;
  margin-top: 1em;
}

.main {
  flex-grow: 1;
  flex-basis: auto;
  width: 46.5625em;
  margin-right: 1.2em;
}

.suggestions {
  flex-shrink: 0;
  flex-basis: auto;
  width: 25.4375em;
}

.dark-overlay {
}

.dark-overlay:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8888;
}

.activate-overlay {
  position: relative;
  z-index: 9999;
}

.cubicaje-form-container {
	display: flex;
	/* width: 66%; */
	width: 100%;
	max-width: 100%;
	flex-direction: column;
}

@media (min-width: 1024px) {  
	.cubicaje-form-container {
		flex: 0 0 auto;
		width: 66.66666667%;
		padding-right: 30px;
	}
}

@media (min-width: 1440px) {
  .cubicaje-form-container {
		
    padding-right: 70px;
	}
}