.custom-tooltip {
    background-color: white !important; /* Usa !important para asegurarte de que sobrescriba otros estilos */
    border-radius: 8px;
    padding: 8px;
    color:  #1A2228 !important;
    font-family: Barlow;
    font-size: 13.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.9px; /* 140% */
  }
  