.price-comparator__container-main {
  background: var(--gray-800, #2E3C46);
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-right: auto;
  /* width: 800px; */
  padding-bottom: 1em;
}

.price-comparator__container-title {
  color: #FFF;
  font-family: Raleway;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px;
  margin-left: 20px;
  margin: 20px 20px 0;
}

.price-comparator___inner {
  color: #FFF;
  font-family: Raleway;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px;
  padding: 0 20px;
  margin-top: 32px;
}

.price-comparator___sub-inner {
  color: #FFF;
  font-family: Raleway;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px;
  padding: 0 20px;
}

.price-comparator__textbox-general {
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  border-top-width: 0.0625em;
  border-right-width: 0.0625em;
  border-bottom-width: 0.0625em;
  border-left-width: 0.0625em;
  border-radius: 0.25em;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  display: flex;
  height: 3.125em;
  margin-bottom: 0.1em;
  padding-left: 0.5em;

  width: 100%;
  color: #ECEEEF;
}

.price-comparator__textbox-general-col1 {
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  border-top-width: 0.0625em;
  border-right-width: 0.0625em;
  border-bottom-width: 0.0625em;
  border-left-width: 0.0625em;
  border-radius: 0.25em;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  display: flex;
  height: 50px;
  margin-bottom: 0.1em;
  padding-left: 0.5em;
  width: 100%;
  color: #ECEEEF;
}
.price-comparator_grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.price-comparator__textbox-double-general-col {
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  border-top-width: 0.0625em;
  border-right-width: 0.0625em;
  border-bottom-width: 0.0625em;
  border-left-width: 0.0625em;
  border-radius: 0.25em;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  display: flex;
  height: 50px;
  padding-left: 0.5em;
  width: 100% ;
  color: #ECEEEF;
}
.price-comparator__doble {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.price-comparator_select_right {
  flex-grow: 1;
}

.price-comparator__textbox-double-general-col2 {
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  border-top-width: 0.0625em;
  border-right-width: 0.0625em;
  border-bottom-width: 0.0625em;
  border-left-width: 0.0625em;
  border-radius: 0.25em;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  display: flex;
  height: 50px;
  padding-left: 0.5em;
  width: 100%;
  color: #ECEEEF;
  margin-left: 0;
}

.price-comparator__textbox-general-col2 {
  align-items: flex-start;
  align-self: stretch;
  background: var(--gray-700, #425563);
  border: 0.0625em solid transparent;
  border-top-width: 0.0625em;
  border-right-width: 0.0625em;
  border-bottom-width: 0.0625em;
  border-left-width: 0.0625em;
  border-radius: 0.25em;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  display: flex;
  height: 50px;
  margin-bottom: 0.1em;
  padding-left: 0.5em;
  width: 100%;
  color: #ECEEEF;
  /* margin-left: -18px; */
}

.price-comparator__container-link {
  color: var(--orange-200, #FFCABF);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px;
  margin-top: 10px;
  text-decoration: none;
  display: block;
}

.price-comparator__container-footer {
  border-top: 0.0625em solid var(--gray-700, #425563);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  align-self: stretch;
  width: 100%;
  margin-top: 24px;
  padding: 16px 40px 0;
}

.price-comparator__btn {
  align-items: center;
  background: var(--orange-400, #FF8166);
  border-radius: 24px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 12px 20px;
  cursor: pointer;
  width: 166px;
  height: 45px;
}

.price-comparator__btn-text {
  color: var(--gray-900, #1A2228);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px;
}

.transition-animation__image-container-video{

  padding-top: 56.25%;
  position: relative;
  display: block;
  width: 100%;
  
}
.transition-animation__container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}


.transition-animation__container video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    position: absolute;
}
.transition-animation__image-container {
  width: 160px;
  height: 470px;
}

.transition-animation__image-container-video {
}

.transition-animation__image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  flex-shrink: 0; 
}


.transition-animation__image {
  max-width: 80%; 
  max-height: 100%;
}

.price-comparator__price-comparator-container-main {
  background: var(--gray-800, #2E3C46);
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-right: auto;
}


.price-comparator__price-comparator-container {
  color: var(--gray-100, #ECEEEF);
  font-family: Raleway;
  font-size: 34.64px;
  font-style: normal;
  font-weight: 700;
  line-height: 48.496px;
  display: flex;
  flex-direction: column;
}

.price-comparator__price-comparator-container-head {
  color: var(--gray-100, #ECEEEF);
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; 
  margin-top: 0;
}

.price-comparator__price-comparator-container-head-title {
  color: var(--gray-100, #ECEEEF);
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.price-comparator__price-comparator-container-title-separator{
  width: 100%;
  height: 1px;
  color:#ECEEEF;
  margin: 20px 0; 
}

.comparador_charts {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40em;
  width: 70em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 5em;
  margin-top: -2em;
}

.price-comparador_charts {
  text-align: center;
  padding: 0;

}

.price-comparador_charts img {
  max-width: 100%;
}

.price-comparator__select-row {
  display: flex;
  justify-content: space-between;
  margin-left: 3em;
  margin-right: 3em;
}

.price-comparator__select-container {
  width: 174px;
  height: 50px;
}

.price-comparator__select-box {
  width: 100%;
  height: 50px;
}

.price-comparator__info-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  gap: 20px;
}

.price-comparator__info-left {
  flex: 1; 
  text-align: left; 
}

.price-comparator__info-right {
  text-align: right; 
  margin-right: 1em;
}

.price-comparator__info-right-button {
  cursor: pointer;
}

.price-comparator__info-count {
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.926px; 
}

.price-comparator__info-order {
  color: var(--orange-200, #FFCABF);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.266px;
}

.price-comparator__labels-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px; 
  margin-left: 3em;
  margin-right: 6em;
  margin-bottom: 1em;
}

.price-comparator__label {
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px; 
}

.price-comparator__panel {
  display: flex;
  width: 1170px;
  padding: 16px 20px;
  align-items: center;
  gap: 32px;
  border-radius: 8px;
  background: var(--gray-700, #425563);
  margin-left: 3em;
  width: 1200px;
}

.price-comparator__image-column {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  cursor: pointer;
}

.price-comparator__text-column {
  width: 230px;
}

.price-comparator__green-panel {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--green-400, #BED5C6);
  cursor: pointer;
}

.price-comparator__bordered-panel {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--green-700, #68796F);
  cursor: pointer;
}

.price-comparator__image-column{
  margin-left: 20px;
}

.price-comparator__icon-column {
  width: 104px;
  padding-left: 0;
  cursor: pointer;
}

.price-comparator__text-column {
  margin-left: 32px;
  width: 230px;
}

.price-comparator__text-column-title {
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 19.22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.908px; 
}

.price-comparator__text-column-subtitle {
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.266px;
}
.price-comparator__text-column-description{
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px; 
}

.price-comparator__green-panel {  
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--green-400, #BED5C6);
  width: 129px;
}

.price-comparator__green-panel-price {
  color: var(--gray-900, #1A2228);
  font-family: Barlow;
  font-size: 15.09px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px; 
}

.price-comparator__green-panel-weight {
  border-radius: 4px;
  background: var(--green-700, #68796F);
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  width: fit-content;
}

.price-comparator__row {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.price-comparator__row .col-12.col-md-6 {
  flex: 1 1 calc(50% - 10px);
}

.price-comparator__row .col-5 {
  display: flex;
  justify-content: flex-end;
}

.price-comparator__panel-price {
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 15.09px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.926px; 
}

.price-comparator__panel-weight {
  border-radius: 4px;
  background: var(--green-700, #68796F);
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  width: fit-content;
}

.price-comparator__image-flag {
  width: 36px;
  height: 24px;
  margin-top: 8px;
}	

.price-comparator__image-ico {
  width: 27px;
  height: 24px;
}	

.price-comparator__image-ico-title {
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.3px;  
  margin-bottom: 8px;
}

.price-comparator__label {
  flex: 0 0 24.5em;
  max-width: 25em;
  text-align: left;
}

.price-comparator__label:nth-child(2) {
  flex: 0 0 7em;
  max-width: 7em;
}

.price-comparator__label:nth-child(3) {
  flex: 0 0 8.5em;
  max-width: 9em;
}

.price-comparator__label:nth-child(n+4):nth-child(-n+7) {
  flex: 0 0 4em;
  max-width: 4em;
}

.price-comparator__paginator {
  display: flex;
  justify-content: flex-end; 
  margin-right: 3em;
  margin-bottom: 1.5em;
  margin-top: 1em;
}

.price-comparator__paginator-items {
  display: flex;
  align-items: center;
}

.price-comparator__paginator-label {
  margin-right: 10px; 
  color: #FFF;
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.266px; /* 140% */
}

.price-comparator__paginator-pages {
  display: flex;
  align-items: center;
  margin-right: 10px; 
}

.price-comparator__paginator-page {
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--gray-700, #425563);
  background: var(--green-900, #232825);
  color: white;
  margin-right: 5px;
}

.price-comparator__paginator-page--selected {
  border-radius: 4px;
  border: 1px solid var(--gray-700, #425563);
  background: var(--green-900, #232825); 
  padding: 8px;
  justify-content: center;
  align-items: center;  
}

.price-comparator__paginator-page-count {
  margin-left: 5px;
  margin-right: 10px; 
  color: #FFF;
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.266px; /* 140% */
}

.price-comparator__paginator-button {
  border-radius: 1.25em;
  background: var(--green-500, #adcab8);
  cursor: pointer;
  display: flex;
  padding: 0.625em 1em;
  justify-content: center;
  align-items: center;
  gap: 0.625em;
}

.price-comparator__paginator-button-text{
  margin-right: 5px;
  color: var(--gray-900, #1a2228);
  font-family: Barlow;
  font-size: 0.84375em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3125em;
}

.price-comparator__paginator-button--prev {
  background: var(--green-500, #ADCAB8);
}

.price-comparator__paginator-button--next {
  background: var(--green-500, #ADCAB8);
}

.price-comparator__sort-icon{
  margin-left: 0.8em;
  cursor: pointer;
}


.price-comparator__price-comparator-container-title {
  flex: 1;
}

.price-comparator__info-right {
  display: flex;
  align-items: center;
}

.price-comparator__sort-icon {
  margin-right: 5px; 
}

.price-comparator__new-comparator-button {
  display: inline-flex;
  height: 39px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--orange-400, #FF8166);
  cursor: pointer;
}

.price-comparator-paises {
  margin-left: 100px;
}

.comparator__container__body{
  padding: 0 20px 10px;
}
.boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
}

.boxes_item {
  border-radius: 12px;
  background: var(--gray-700, #425563);
  gap: 12px;
  display: flex;
  /* flex-basis: 48.33%; */
  min-height: 100px;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  flex-grow: 1;
  width: 100%;
}

.boxes_item-text {
  font-family: 'Barlow', 'Raleway', sans-serif;
  color: #fff;
  font-weight: 600;
}

.boxes_item-img {
  position: relative;
  border-radius: 40px;
  background: var(--gray-600, #5E6F7A);
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.country-list {
  background: var(--gray-700, #425563);
  border-radius: 0.25em;
  width: 100%;
  color: #ECEEEF;
  padding: 15px;
  display: flex;
  margin-top: 1em;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  gap: 10px;
}

.country-item {
  color: var(--gray-200, #D4D8DB) !important;
  font-family: Barlow !important;
  font-size: 13.5px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18.9px !important;
  display: flex;
  min-width: 0;
  background-color: #2E3C46;
  border-radius: 12px;
  padding: 4px 16px;
  margin: 0;
  box-sizing: border-box;
  box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
  color: #D4D8DB;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6,
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-4{
  max-width: inherit !important;
}
.tss-oeo5eo-MUIDataTableToolbar-filterPaper{
  left: 20px !important;
  right: 20px !important;
  max-width: inherit !important;
}

.price_comparator-box-1,
.price_comparator-box-2 {
  border-radius: 8px;
  background: var(--green-400, #BED5C6);
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  margin: 0 0 0;
}
.price_comparator-box-1 p ,
.price_comparator-box-2 p {
display: flex;
align-items: center;
gap: 24px;
align-self: stretch;
margin: 0;
justify-content: space-between;
}

.price_comparator-box-price {
  color: var(--gray-900, #1A2228);
  font-family: Barlow;
  font-size: 17.09px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.926px; /* 140% */
  white-space: nowrap;
}
.price_comparator-box-weight {
  border-radius: 4px;
  background: var(--green-700, #68796F);
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
}

.price_comparator-box-2  {
  border-radius: 8px;
  border: 1px solid var(--green-700, #68796F);
  background-color: transparent;
}

.price_comparator-box-2 .price_comparator-box-price {
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 17.09px;
  font-weight: 400;
}

.price_comparator-col1-title {
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 19.22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.908px; /* 140% */
}

.price_comparator-col1-subtitle {
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 15.19px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.266px; /* 140% */
  margin-bottom: 12px;
}

.price_comparator-col1-description {
  color: var(--gray-100, #ECEEEF);
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px; /* 140% */
}

.price-calc__tag {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #F4B400;
  color: var(--gray-900, #1A2228);
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px; /* 140% */
}

.price-calculator-resume__footer {
  position: relative;
}

.price-calculator-resume__footer-link {
  position: absolute;
  left: 20px;
  padding: 0;
  bottom: 16px;
  border: none;
  font-size: 14px;
  color: #FFCABF;
  font-weight: 600;
  font-family: Barlow;
  background: none;
}

@media (min-width: 768px) {
  .boxes_item {
    flex-basis: 48.33%;
    flex-grow: inherit;
    width: inherit;
  }
  .comparator__container__body {
    padding: 0 40px 30px;
  }
  .price-comparator__container-title{
    margin: 30px 40px 0;
  }
  .price-comparator___inner {
    padding: 0 40px;
  }
  
  .price-comparator__row {
    flex-direction: row;
  }
  .price-comparator___sub-inner{
    padding: 0 40px;
  }
  .price-calculator-resume__footer-link {
    bottom: 32px;
    left: 40px;
  } 
  .price_comparator-box-1,
  .price_comparator-box-2 {
   margin-right: 15px; 
  }
}
