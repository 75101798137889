.conteoPalletsPestanaUno {
	color: var(--gray-100, #eceeef) !important;
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 400;
	line-height: 21.266px;

	span {
		color: var(--orange-200, #ffcabf) !important;
		font-family: Barlow;
		font-size: 15.19px;
		font-style: normal;
		font-weight: 600;
		line-height: 21.266px; /* 140% */
	}
}

.palletSelected {
	background: var(--green-400, #bed5c6) !important;

	div p {
		color: #000000 !important;
	}
	p {
		color: #000000 !important;
	}
}

.card-text {
	font-family: 'Barlow' !important;
	p {
		font-family: 'Barlow' !important;
	}
}

.card-body {
	font-family: 'Barlow' !important;
	p {
		font-family: 'Barlow' !important;
	}
}

.rowcubicaje-container {
	margin-top: 15px;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 15px;
	align-items: stretch;
}
.rowcubicaje-col{
	flex: 1;
	width: 100%;
}

.selectCantidadPallet {
	display: flex;
    align-items: center;
	width: 100%;
}

.rowcubicaje-col .CustomizedSelect__container {
	width: 100%;
	align-items: flex-start;
	max-width: 80px;
}

.rowcubicaje-container {
display: grid;
grid-template-columns: 80px 1fr; /* Primera columna fija en 80px, segunda columna flexible */
grid-template-rows: auto auto; /* Dos filas de altura automática */
gap: 15px; /* Espacio entre los elementos */
}

.rowcubicaje-min {
grid-column: 1; /* Primera columna, primera fila */
grid-row: 1;
}

.rowcubicaje-rightmin {
grid-column: 2; /* Segunda columna, primera fila */
grid-row: 1;
}

.rowcubicaje-half:nth-of-type(1) {
grid-column: 1 / span 2; /* Primera columna en la segunda fila, ocupando ambas columnas */
grid-row: 2;
}

.rowcubicaje-half:nth-of-type(2) {
grid-column: 1 / span 2; /* Segunda columna en la segunda fila, ocupando ambas columnas */
grid-row: 2;
}
  

.container_selected_cubicaje {
	width: 500px !important;
}

.cubicaje-container_father {
	color: #fff4f4;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.93px;
	margin-bottom: 14px;
	margin-top: 0;
	/* max-width: 48em; */
	padding: 0 20px;
}

.inputItem {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-radius: 0.25em;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 50px;
	color: #eceeef;
	width: 100%;
}

.inputItemWeight {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-radius: 0.25em;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 50px;
	color: #eceeef;
	width: 100%;
}

.cubicaje_textb_medidasox-producto {
	align-items: flex-start;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-radius: 4px 0px 0px 0px;
	box-shadow: 0px 0.125em 0.25em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 3.125em;
	padding-left: 0.5em;
	width: 100%;
	color: #eceeef;
}

.titleCubicajeResumen {
	font-weight: bold;
}

.btn-cubicajeNuevo-text {
	color: var(--gray-900, #1a2228);
	font-family: Barlow;
	font-size: 0.84375em;
	font-style: normal;
	font-weight: 600;
	line-height: 1.3125em;
}

.cubicajeNuevo__next {
	display: flex;
	padding: 0.625em 1em;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 80%;
	transform: translateX(-50%);
	cursor: pointer;
	width: 150px;
	border-radius: 1.25em;
	background: #ff8166;
}

.frame-111-M3B {
	align-items: center;
	border-top: 1px solid #425563;
	box-sizing: border-box;
	display: flex;
	flex-shrink: 0;
	/* height: 7.1rem; */
	padding: 20px 20px 5px;
	justify-content: space-between;
}

.botonesCubicajeContainer {
	cursor: pointer;
	align-items: center;
	border-radius: 1.5rem;
	box-sizing: border-box;
	display: flex;
	flex-shrink: 0;
	height: 100%;
	
	padding: 0;
}

.buttonsCubicaje {
	color: #ffcabf;
	display: flex;
	flex-shrink: 0;
	margin-left: 10px;
	font-family: Barlow;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.4000000879;
	white-space: nowrap;
}

.seccionBotonesCubicaje {
	align-items: center;
	border: solid 0.1rem #425563 !important;
	box-sizing: border-box;
	display: flex;
	flex-shrink: 0;
	height: 7.1rem;
	padding: 1.6rem 4rem;
}

.cardsCubicaje {
	background: #cedfd5;
	width: 200px !important;

	h5 {
		color: #2e3c46 !important;
		font-family: Barlow;
	}

	p {
		color: #2e3c46 !important;
		font-size: 15px;
	}
}

.colorsubTextosResumen {
	color: white;
}

.containerResumenCubicaje {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	padding: 3.863rem 3.7rem 4rem 3.1rem;
	width: 100%;
}

.typePallet {
}

.backgroundDivCompleto {
	background-color: #425563;
	border-radius: 0.8rem 0 0 0.8rem;
	width: 570px;
	padding: 0.7rem 0 0.1rem 1.3rem;
	height: 60px;

	.selectControl {
		width: 80%;
	}

	.labelUnidad {
		color: #bbc2c7;
		font-family: Barlow;
		font-size: 15.19px;
		top: 0;
	}
}

.textBackgrouns {
	background-color: #425563 !important;
	color: #d9d9d9;
	border-radius: 0% !important;
	border: 0px !important;
}

.textBackgrouns:focus {
	background-color: #425563 !important;
	color: #d9d9d9;
	border-radius: 0% !important;
	border: 0px !important;
	outline: #000000;
}

.textBackgrouns::placeholder {
	color: #bbc2c7;
}

.backgroundDivInicio {
	background-color: #425563;
	border-radius: 0.8rem 0 0 0.8rem;
	width: 120px;
	padding: 0.7rem 0 0.1rem 1.3rem;
	height: 60px;

	.selectControl {
		width: 80px;
	}

	.labelUnidad {
		color: #bbc2c7;
		font-family: Barlow;
		font-size: 15.19px;
		top: 0;
	}
}

.backgroundDivDerecha {
	background-color: #425563;
	border-radius: 0 0 0 0;
	width: 120px;
	padding: 10px 0 0 0;
	height: 60px;
	margin-left: 5px;

	.selectControl {
		width: 80px;
	}

	.labelUnidad {
		color: #bbc2c7;
		font-family: Barlow;
		font-size: 15.19px;
		top: 0;
	}
}
.cubicaje-fieldset{
	display: flex;
	align-items: center;
}
.cubicaje-fieldset-tooltip {
    margin-left: 12px;
    margin-top: 14px;
}

.auto-group-6qah-KNM {
	align-items: center;
	box-sizing: border-box;
	flex-direction: column;
	padding-top: 2em;
	width: 100%;
	

	.auto-group-pspf-ddw:hover {
		background-color: #bed5c6;
		cursor: pointer;
		div p {
			color: #000000 !important;
		}
		p {
			color: #000000 !important;
		}
	}

	.auto-group-pspf-ddw {
		transition: .2s all ease-out;
		background-color: #425563;
		border-radius: 15px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		margin-bottom: 1.6rem;
		padding: 0.7rem 4px 0px 24px;

		.auto-group-pund-wed {
			align-items: flex-start;
			display: flex;
			flex-shrink: 0;
			/* height: 3.2rem; */
			/* margin-bottom: 0.6rem; */
			width: 100%;

			.europallet-U8m {
				color: #eceeef;
				/* flex-shrink: 0; */
				font-family: Barlow;
				font-size: 18px;
				font-weight: 700;
				line-height: 20px;
				white-space: unset;

				margin-top: 10px;
                margin-bottom: 15px;
			}
			.auto-group-emux-CaZ {
				align-items: center;
				box-sizing: border-box;
				display: flex;
				flex-shrink: 0;
				/* height: 100%; */
				padding: 9px 0 0 10px;

				.ellipse-23-LRs {
					background-color: #d9d9d9;
					border-radius: 0.4rem;
					flex-shrink: 0;
					/* height: 0.8rem; */
					margin: 0rem 1rem 0.4rem 0rem;
					/* width: 0.8rem; */
				}
				.auto-group-6qav-prq {
					align-items: center;
					background-color: #FF8166;
					color: #1A2228;
					display: flex;
					flex-shrink: 0;
					font-family: Barlow;
					font-size: 12px;
					font-weight: 400;
					/* height: 2rem; */
					justify-content: center;
					line-height: 1.3333333333;
					margin: 0;
					font-weight: 500;
					white-space: nowrap;
					/* width: 16.7rem; */
					margin-left: 15px;
					padding: 5px;
				}
				.ellipse-21-JGD {
					background-color: #7b8892;
					border-radius: 1.3rem;
					flex-shrink: 0;
					/* height: 2.6rem;
					width: 2.6rem; */
				}
			}
		}
		.group-84-cXo {
			/* align-items: center; */
			display: flex;
			flex-shrink: 0;
			/* height: 3.2rem; */
			margin: 0;
			flex-direction: column;

			.auto-group-6spv-9Gq {
				flex-shrink: 0;
				/* height: 100%; */
				margin-right: 4.8rem;
				position: relative;
				/* width: 5.5rem; */
				display: flex;
                flex-direction: column-reverse;

				.x800x145mm-ToK {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 400;
					/* height: 1.7rem; */
					/* left: 0; */
					line-height: 1.3999999364;
					/* position: absolute; */
					/* top: 1.5rem; */
					white-space: nowrap;
					/* width: 9.3rem; */
				}
				.ancho-x-largo-x-alto-mJD {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 700;
					/* height: 1.7rem; */
					/* left: 0; */
					line-height: 1.3999999364;
					/* position: absolute; */
					/* top: 0; */
					white-space: nowrap;
					/* width: 10.5rem; */
					margin-bottom: 5px;
				}
			}
			.auto-group-faqu-S9T {
				flex-shrink: 0;
				/* height: 100%; */
				margin-right: 3.8rem;
				position: relative;
				/* width: 3.8rem; */

				.peso-x-pallet-nUD {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 700;
					/* height: 1.7rem;
					left: 0; */
					line-height: 1.3999999364;
					/* position: absolute; */
					/* top: 0; */
					white-space: nowrap;
					margin-bottom: 5px;
					/* width: 6.8rem; */
				}
				.kg-4wX {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 400;
					/* height: 1.7rem; */
					/* left: 0; */
					line-height: 1.3999999364;
					/* position: absolute;
					top: 1.5rem; */
					white-space: nowrap;
					/* width: 2.7rem; */
				}
			}
			.auto-group-pxb3-aus {
				flex-shrink: 0;
				/* height: 100%; */
				margin-right: 3.9rem;
				position: relative;
				/* width: 6.2rem; */

				.carga-dinmica-vyj {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 700;
					/* height: 1.7rem; */
					/* left: 0; */
					line-height: 1.3999999364;
					/* position: absolute;
					top: 0; */
					white-space: nowrap;
					margin-bottom: 5px;
					/* width: 8.2rem; */
				}
				.kg-RvV {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 400;
					/* height: 1.7rem;
					left: 0; */
					line-height: 1.3999999364;
					/* position: absolute;
					top: 1.5rem; */
					white-space: nowrap;
					/* width: 4rem; */
				}
			}
			.auto-group-2gox-MZF {
				flex-shrink: 0;
				/* height: 100%; */
				position: relative;
				/* width: 7.8rem; */

				.carga-esttica-7HX {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 700;
					/* height: 1.7rem; */
					left: 0;
					line-height: 1.3999999364;
					/* position: absolute; */
					/* top: 0; */
					white-space: nowrap;
					margin-bottom: 5px;
					/* width: 7.8rem; */
				}
				.kg-ow3 {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 400;
					/* height: 1.7rem;
					left: 0; */
					line-height: 1.3999999364;
					/* position: absolute;
					top: 1.5rem; */
					white-space: nowrap;
					/* width: 4.2rem; */
				}
			}
		}
		.es-el-ms-usado-en-europa-debido-a-las-dimensiones-de-las-cajas-de-los-remolques-XMF {
			color: #bbc2c7;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 15px;
			font-weight: 400;
			line-height: 1.3999999364;
			margin-right: 5.9rem;
			white-space: nowrap;
		}
	}
	.auto-group-nym3-PuF {
		align-items: center;
		background-color: #425563;
		border-radius: 15px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		padding: 15px 1.1rem 2.7rem 2.2rem;
		width: 100%;

		.auto-group-uueh-Wys {
			align-items: flex-start;
			display: flex;
			flex-shrink: 0;
			margin: 0rem 0rem 0.2rem 0.2rem;
			width: calc(100% - 0.2rem);

			.pallet-arlog-2BX {
				color: #eceeef;
				flex-shrink: 0;
				font-family: Barlow;
				font-size: 15.19px;
				font-weight: 700;
				line-height: 2.1066491695;
				margin-right: 42.5rem;
				white-space: nowrap;
			}
			.ellipse-22-Mjb {
				background-color: #7b8892;
				border-radius: 1.3rem;
				flex-shrink: 0;
				/* height: 2.6rem;
				width: 2.6rem; */
			}
		}
		.group-85-tzR {
			align-items: center;
			display: flex;
			flex-shrink: 0;
			/* height: 3.2rem; */
			margin-right: 7.9rem;

			.auto-group-7xn5-F4H {
				flex-shrink: 0;
				/* height: 100%; */
				margin-right: 4.8rem;
				position: relative;
				/* width: 10.5rem; */

				.x1000x145mm-bP3 {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 400;
					/* height: 1.7rem; */
					/* left: 0; */
					line-height: 1.3999999364;
					position: absolute;
					/* top: 1.5rem; */
					white-space: nowrap;
					/* width: 9.8rem; */
				}
				.ancho-x-largo-x-alto-Hmf {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 700;
					/* height: 1.7rem;
					left: 0; */
					line-height: 1.3999999364;
					/* position: absolute; */
					/* top: 0; */
					white-space: nowrap;
					/* width: 10.5rem; */
				}
			}
			.auto-group-ysb3-D9X {
				flex-shrink: 0;
				/* height: 100%; */
				margin-right: 4.3rem;
				position: relative;
				/* width: 6.8rem; */

				.peso-x-pallet-81b {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 700;
					/* height: 1.7rem; */
					/* left: 0; */
					line-height: 1.3999999364;
					/* position: absolute; */
					/* top: 0; */
					white-space: nowrap;
					/* width: 6.8rem; */
				}
				.kg-FM7 {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 400;
					/* height: 1.7rem;
					left: 0; */
					line-height: 1.3999999364;
					/* position: absolute;
					top: 1.5rem; */
					white-space: nowrap;
					/* width: 2.8rem; */
				}
			}
			.auto-group-gdvo-Ays {
				flex-shrink: 0;
				/* height: 100%; */
				margin-right: 3.4rem;
				position: relative;
				/* width: 8.2rem; */

				.carga-dinmica-vTF {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 700;
					/* height: 1.7rem;
					left: 0; */
					line-height: 1.3999999364;
					/* position: absolute;
					top: 0; */
					white-space: nowrap;
					/* width: 8.2rem; */
				}
				.kg-SgV {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 400;
					/* height: 1.7rem;
					left: 0; */
					line-height: 1.3999999364;
					/* position: absolute;
					top: 1.5rem; */
					white-space: nowrap;
					/* width: 4rem; */
				}
			}
			.auto-group-txih-a21 {
				flex-shrink: 0;
				/* height: 100%; */
				position: relative;
				/* width: 7.8rem; */

				.carga-esttica-83X {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 700;
					/* height: 1.7rem; */
					/* left: 0; */
					line-height: 1.3999999364;
					/* position: absolute; */
					/* top: 0; */
					white-space: nowrap;
					/* width: 7.8rem; */
				}
				.kg-2Po {
					color: #eceeef;
					font-family: Barlow;
					font-size: 15px;
					font-weight: 400;
					/* height: 1.7rem; */
					/* left: 0; */
					line-height: 1.3999999364;
					/* position: absolute;
					top: 1.5rem; */
					white-space: nowrap;
					/* width: 4.1rem; */
				}
			}
		}
	}
}

.es-el-ms-usado-en-europa-debido-a-las-dimensiones-de-las-cajas-de-los-remolques-XMF:empty{
	display: none;
}
/* Accaa termina */

.auto-group-insx-LS1 {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 100%;
	margin-left: -120px !important;

	.auto-group-v6vq-3bK {
		align-items: center;
		column-gap: 0.2rem;
		display: flex;
		flex-shrink: 0;
		height: 4.8rem;
		margin-bottom: 15px;
		width: 100%;

		.auto-group-textbox-last {
			align-items: center;
			background-color: #425563;
			border-radius: 0 0.8rem 0.8rem 0;
			box-sizing: border-box;
			display: flex;
			flex-shrink: 0;
			height: 100%;
			padding: 0.7rem 4.2rem 0.1rem 1.3rem;

			.auto-group-jgd3-eLD {
				flex-shrink: 0;
				height: 100%;
				margin-right: 2.8998rem;
				position: relative;
				width: 3.7rem;

				.mm-mQqTextbox {
					color: #eceeef;
					background-color: #425563;
					font-family: Barlow;
					font-size: 19px;
					font-weight: 400;
					height: 35px;
					left: -5px;
					line-height: 2.1333333333;
					position: absolute;
					top: 25px;
					white-space: nowrap;
					width: 120px;
				}
				.unidad-ToT {
					color: #bbc2c7;
					font-family: Barlow;
					font-size: 15.19px;
					font-weight: 400;
					height: 1.2rem;
					left: 0;
					line-height: 1;
					position: absolute;
					top: 0;
					white-space: nowrap;
					width: 3.7rem;
				}
			}
			.caret-down-solid-4-ZLh {
				flex-shrink: 0;
				height: 0.6002rem;
				margin-bottom: 0.8004rem;
				object-fit: contain;
				vertical-align: top;
				width: 1.0004rem;
			}
		}

		.auto-group-textbox {
			align-items: center;
			background-color: #425563;
			box-sizing: border-box;
			display: flex;
			flex-shrink: 0;
			height: 100%;
			width: 160px;

			.auto-group-jgd3-eLD {
				flex-shrink: 0;
				height: 100%;
				margin-right: 2.8998rem;
				position: relative;
				width: 3.7rem;

				.mm-mQqTextbox {
					color: #eceeef;
					background-color: #425563;
					font-family: Barlow;
					font-size: 19px;
					font-weight: 400;
					height: 35px;
					left: -5px;
					line-height: 2.1333333333;
					position: absolute;
					top: 25px;
					white-space: nowrap;
					width: 120px;
				}
				.unidad-ToT {
					color: #bbc2c7;
					font-family: Barlow;
					font-size: 15.19px;
					font-weight: 400;
					height: 1.2rem;
					left: 0;
					line-height: 1;
					position: absolute;
					top: 0;
					white-space: nowrap;
					width: 3.7rem;
				}
			}
			.caret-down-solid-4-ZLh {
				flex-shrink: 0;
				height: 0.6002rem;
				margin-bottom: 0.8004rem;
				object-fit: contain;
				vertical-align: top;
				width: 1.0004rem;
			}
		}

		.auto-group-hxmf-Mru {
			align-items: center;
			background-color: #425563;
			border-radius: 0.8rem 0 0 0.8rem;
			box-sizing: border-box;
			display: flex;
			flex-shrink: 0;
			height: 100%;
			padding: 0.7rem 1.2rem 0.1rem 1.3rem;

			.auto-group-jgd3-eLD {
				flex-shrink: 0;
				height: 100%;
				margin-right: 2.8998rem;
				position: relative;
				width: 3.7rem;

				.mm-mQq {
					color: #eceeef;
					font-family: Barlow;
					font-size: 19px;
					font-weight: 400;
					height: 35px;
					left: -5px;
					line-height: 2.1333333333;
					position: absolute;
					top: 25px;
					white-space: nowrap;
					width: 120px;
				}
				.unidad-ToT {
					color: #bbc2c7;
					font-family: Barlow;
					font-size: 15.19px;
					font-weight: 400;
					height: 1.2rem;
					left: 0;
					line-height: 1;
					position: absolute;
					top: 0;
					white-space: nowrap;
					width: 3.7rem;
				}
			}
			.caret-down-solid-4-ZLh {
				flex-shrink: 0;
				height: 0.6002rem;
				margin-bottom: 0.8004rem;
				object-fit: contain;
				vertical-align: top;
				width: 1.0004rem;
			}
		}
		.auto-group-ybfk-gZj {
			background-color: #425563;
			box-sizing: border-box;
			color: #bbc2c7;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 1.5rem;
			font-weight: 400;
			height: 100%;
			line-height: 0.8;
			padding: 1.6rem 1.7rem 2rem 1.7rem;
			white-space: nowrap;
			width: 16.7rem;
		}
		.auto-group-plih-9CR {
			background-color: #425563;
			box-sizing: border-box;
			color: #bbc2c7;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 1.5rem;
			font-weight: 400;
			height: 100%;
			line-height: 0.8;
			padding: 1.6rem 1.7rem 2rem 1.7rem;
			white-space: nowrap;
			width: 16.3rem;
		}
		.auto-group-s6sy-p3f {
			background-color: #425563;
			border-radius: 0 0.8rem 0.8rem 0;
			box-sizing: border-box;
			color: #bbc2c7;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 1.5rem;
			font-weight: 400;
			height: 100%;
			line-height: 0.8;
			padding: 1.6rem 1.9rem 2rem 1.9rem;
			white-space: nowrap;
			width: 15.8rem;
		}
	}
	.indica-el-peso-por-caja-snd {
		color: #fff3f3;
		flex-shrink: 0;
		font-family: Raleway, 'Source Sans Pro';
		font-size: 1.519rem;
		font-weight: 700;
		line-height: 1.5751152901;
		margin: 0rem 0rem 1.3rem 0.5rem;
		white-space: nowrap;
	}
	.auto-group-klcd-NjP {
		align-items: center;
		display: flex;
		flex-shrink: 0;
		height: 4.8rem;
		margin-right: 1.3rem;
		width: calc(100% - 1.3rem);

		.auto-group-u2jw-sec {
			align-items: center;
			background-color: #425563;
			border-radius: 0 0.8rem 0.8rem 0;
			box-sizing: border-box;
			display: flex;
			flex-shrink: 0;
			height: 100%;
			margin-right: 0.2rem;
			padding: 0.7rem 1.2rem 0.1rem 1.3rem;
			width: 100%;

			.auto-group-textbox-sec {
				flex-shrink: 0;
				height: 100%;
				margin-right: 2.8998rem;
				position: relative;
				width: 500px;
				background-color: #425563;

				.kg-JmT {
					color: #eceeef;
					background-color: #425563;
					font-family: Barlow;
					font-size: 19px;
					font-weight: 400;
					height: 35px;
					left: -5px;
					line-height: 2.1333333333;
					position: absolute;
					top: 25px;
					white-space: nowrap;
					width: 100%;
				}
				.unidad-d33 {
					color: #bbc2c7;
					font-family: Barlow;
					font-size: 15.19px;
					font-weight: 400;
					height: 1.2rem;
					left: 0;
					line-height: 1;
					position: absolute;
					top: 0;
					white-space: nowrap;
					width: 3.7rem;
				}
			}
			.caret-down-solid-5-YA1 {
				flex-shrink: 0;
				height: 0.6002rem;
				margin-bottom: 0.8004rem;
				object-fit: contain;
				vertical-align: top;
				width: 1.0004rem;
			}
		}

		.auto-group-u2jw-thj {
			align-items: center;
			background-color: #425563;
			border-radius: 0.8rem 0 0 0.8rem;
			box-sizing: border-box;
			display: flex;
			flex-shrink: 0;
			height: 100%;
			margin-right: 0.2rem;
			padding: 0.7rem 1.2rem 0.1rem 1.3rem;

			.auto-group-jbmw-xxV {
				flex-shrink: 0;
				height: 100%;
				margin-right: 2.8998rem;
				position: relative;
				width: 3.7rem;

				.kg-JmT {
					color: #eceeef;
					font-family: Barlow;
					font-size: 19px;
					font-weight: 400;
					height: 35px;
					left: -5px;
					line-height: 2.1333333333;
					position: absolute;
					top: 25px;
					white-space: nowrap;
					width: 120px;
				}
				.unidad-d33 {
					color: #bbc2c7;
					font-family: Barlow;
					font-size: 15.19px;
					font-weight: 400;
					height: 1.2rem;
					left: 0;
					line-height: 1;
					position: absolute;
					top: 0;
					white-space: nowrap;
					width: 3.7rem;
				}
			}
			.caret-down-solid-5-YA1 {
				flex-shrink: 0;
				height: 0.6002rem;
				margin-bottom: 0.8004rem;
				object-fit: contain;
				vertical-align: top;
				width: 1.0004rem;
			}
		}
		.auto-group-hku9-quo {
			background-color: #425563;
			border-radius: 0 0.8rem 0.8rem 0;
			box-sizing: border-box;
			color: #bbc2c7;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 1.5rem;
			font-weight: 400;
			height: 100%;
			line-height: 2.1333333333;
			padding: 0.9rem 1.3rem 0.7rem 1.3rem;
			white-space: nowrap;
			width: 47.9rem;
		}
	}
}

.auto-group-wrs9-G7f {
	flex-shrink: 0;
	height: 35px;
	width: 100%;

	.captura-de-pantalla-2024-01-10-a-las-2347-1-ae9 {
		height: 37.4rem;
		left: 114.5rem;
		object-fit: cover;
		position: absolute;
		top: 0;
		vertical-align: top;
		width: 13.2rem;
	}
	.rectangle-83-Gms {
		background-color: #2e3c46;
		border-radius: 1.2rem;
		height: 64.7rem;
		left: 0;
		position: absolute;
		top: 19px;
		width: 65.2rem;
	}
	.group-99-Na1 {
		align-items: center;
		background-color: #425563;
		border-radius: 0.8rem;
		box-sizing: border-box;
		display: flex;
		height: 4.8rem;
		left: 2.7rem;
		padding: 0.8rem 1.6rem 0.8rem 2.3rem;
		position: absolute;
		top: 49.8rem;
		width: 46rem;

		.tipo-de-container-enR {
			color: #bbc2c7;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 19px;
			font-weight: 400;
			line-height: 2.2857142857;
			margin-right: 29.3998rem;
			white-space: nowrap;
		}
		.caret-down-solid-5-B1f {
			flex-shrink: 0;
			height: 0.6002rem;
			margin-bottom: 0.2004rem;
			object-fit: contain;
			vertical-align: top;
			width: 1.0004rem;
		}
	}
	.quieres-calcular-cunto-pallets-entran-en-un-container-tAy {
		color: #fff3f3;
		font-family: Raleway, 'Source Sans Pro';
		font-size: 15px;
		font-weight: 700;
		height: 2.4rem;
		left: 2.7rem;
		line-height: 1.5751152901;
		position: absolute;
		top: 46.1rem;
		white-space: nowrap;
		width: 40.8rem;
	}
	.rectangle-91-MKT {
		background-color: #425563;
		border-radius: 0 0.8rem 0.8rem 0;
		height: 4.8rem;
		left: 12.9rem;
		position: absolute;
		top: 38.5rem;
		width: 47.9rem;
	}
	.altura-mxima-por-pallet-TtH {
		color: #bbc2c7;
		font-family: Barlow;
		font-size: 19px;
		font-weight: 400;
		height: 3.2rem;
		left: 14.2rem;
		line-height: 2.1333333333;
		position: absolute;
		top: 39.4rem;
		white-space: nowrap;
		width: 17.2rem;
	}
	.rectangle-92-woT {
		background-color: #425563;
		border-radius: 0.8rem 0 0 0.8rem;
		height: 4.8rem;
		left: 2.6rem;
		position: absolute;
		top: 38.5rem;
		width: 10.1rem;
	}
	.mm-SVK {
		color: #eceeef;
		font-family: Barlow;
		font-size: 19px;
		font-weight: 400;
		height: 3.2rem;
		left: 3.9rem;
		line-height: 2.1333333333;
		position: absolute;
		top: 40rem;
		white-space: nowrap;
		width: 2.7rem;
	}
	.caret-down-solid-6-M6V {
		height: 0.6002rem;
		left: 10.4998rem;
		object-fit: contain;
		position: absolute;
		top: 40.4997rem;
		vertical-align: top;
		width: 1.0004rem;
	}
	.unidad-TvD {
		color: #bbc2c7;
		font-family: Barlow;
		font-size: 15.19px;
		font-weight: 400;
		height: 1.2rem;
		left: 3.9rem;
		line-height: 1;
		position: absolute;
		top: 39.2rem;
		white-space: nowrap;
		width: 3.7rem;
	}
	.conoces-el-peso-mximo-requerido-por-las-empresas-de-transporte-k8d {
		color: #fff3f3;
		font-family: Raleway, 'Source Sans Pro';
		font-size: 15px;
		font-weight: 700;
		height: 2.4rem;
		left: 2.7rem;
		line-height: 1.5751152901;
		position: absolute;
		top: 10.7rem;
		white-space: nowrap;
		width: 50.3rem;
	}
	.conoces-la-altura-mxima-requerido-por-las-empresas-de-transporte-o-el-potencial-clientes-1aM {
		color: #fff3f3;
		font-family: Raleway, 'Source Sans Pro';
		font-size: 15px;
		font-weight: 700;
		height: 4.8rem;
		left: 2.7rem;
		line-height: 1.5751152901;
		position: absolute;
		top: 27.2rem;
		width: 53.3rem;
	}
	.rectangle-89-fQ1 {
		background-color: #425563;
		border-radius: 0 0.8rem 0.8rem 0;
		height: 4.8rem;
		left: 12.9rem;
		position: absolute;
		top: 19.6rem;
		width: 47.9rem;
	}
	.peso-mximo-por-pallet-mxq {
		color: #bbc2c7;
		font-family: Barlow;
		font-size: 19px;
		font-weight: 400;
		height: 3.2rem;
		left: 14.2rem;
		line-height: 2.1333333333;
		position: absolute;
		top: 20.5rem;
		white-space: nowrap;
		width: 16.6rem;
	}
	.frame-111-UsF {
		border: solid 0.1rem #425563;
		box-sizing: border-box;
		height: 7.1rem;
		left: 0;
		padding: 1.6rem 4rem 1.6rem 51.6rem;
		position: absolute;
		top: 59rem;
		width: 65.2rem;

		.frame-112-PUR {
			align-items: center;
			background-color: #adcab8;
			border-radius: 2rem;
			color: #1a2228;
			display: flex;
			font-family: Barlow;
			font-size: 1.35rem;
			font-weight: 600;
			height: 100%;
			justify-content: center;
			line-height: 1.3999999717;
			white-space: nowrap;
			width: 100%;
		}
	}
	.frame-55-TjB {
		align-items: center;
		border: solid 0.1rem #425563;
		box-sizing: border-box;
		display: flex;
		height: 5.2rem;
		left: 0;
		padding: 1.2rem 21.7rem 1.2rem 2rem;
		position: absolute;
		top: 1.8rem;
		width: 65.2rem;

		.frame-115-C4V {
			align-items: center;
			display: flex;
			flex-shrink: 0;
			height: 100%;
			margin-right: 4.1rem;

			.frame-113-wGy {
				align-items: center;
				background-color: #bbc2c7;
				border-radius: 1.6rem;
				color: #425563;
				display: flex;
				flex-shrink: 0;
				font-family: Barlow;
				font-size: 1.519rem;
				font-weight: 600;
				height: 100%;
				justify-content: center;
				line-height: 1.4000000879;
				margin-right: 1.2rem;
				white-space: nowrap;
				width: 2.8rem;
			}
			.tipo-de-pallet-pbf {
				color: #bbc2c7;
				flex-shrink: 0;
				font-family: Barlow;
				font-size: 1.519rem;
				font-weight: 400;
				line-height: 1.4000000879;
				white-space: nowrap;
			}
		}
		.frame-116-Z3T {
			align-items: center;
			display: flex;
			flex-shrink: 0;
			height: 100%;
			margin-right: 4rem;

			.frame-113-6p5 {
				align-items: center;
				background-color: #bbc2c7;
				border-radius: 1.6rem;
				color: #425563;
				display: flex;
				flex-shrink: 0;
				font-family: Barlow;
				font-size: 1.519rem;
				font-weight: 600;
				height: 100%;
				justify-content: center;
				line-height: 1.4000000879;
				margin-right: 1.2rem;
				white-space: nowrap;
				width: 2.8rem;
			}
			.paquete-zPf {
				color: #bbc2c7;
				flex-shrink: 0;
				font-family: Barlow;
				font-size: 1.519rem;
				font-weight: 400;
				line-height: 1.4000000879;
				white-space: nowrap;
			}
		}
		.frame-117-eUD {
			align-items: center;
			display: flex;
			flex-shrink: 0;
			height: 100%;

			.frame-113-QTP {
				align-items: center;
				background-color: #ff8166;
				border-radius: 1.6rem;
				color: #1a2228;
				display: flex;
				flex-shrink: 0;
				font-family: Barlow;
				font-size: 1.519rem;
				font-weight: 600;
				height: 100%;
				justify-content: center;
				line-height: 1.4000000879;
				margin-right: 1.2rem;
				white-space: nowrap;
				width: 2.8rem;
			}
			.capacidad-VDw {
				color: #eceeef;
				flex-shrink: 0;
				font-family: Barlow;
				font-size: 1.519rem;
				font-weight: 600;
				line-height: 1.4000000879;
				white-space: nowrap;
			}
		}
	}
	.rectangle-90-Cu3 {
		background-color: #425563;
		border-radius: 0.8rem 0 0 0.8rem;
		height: 4.8rem;
		left: 2.6rem;
		position: absolute;
		top: 19.6rem;
		width: 10.1rem;
	}
	.kg-7FK {
		color: #eceeef;
		font-family: Barlow;
		font-size: 1.5rem;
		font-weight: 400;
		height: 3.2rem;
		left: 3.9rem;
		line-height: 2.1333333333;
		position: absolute;
		top: 21.1rem;
		white-space: nowrap;
		width: 1.7rem;
	}
	.caret-down-solid-5-cxm {
		height: 0.6002rem;
		left: 10.4998rem;
		object-fit: contain;
		position: absolute;
		top: 21.5997rem;
		vertical-align: top;
		width: 1.0004rem;
	}
	.unidad-jnV {
		color: #bbc2c7;
		font-family: Barlow;
		font-size: 15.19px;
		font-weight: 400;
		height: 1.2rem;
		left: 3.9rem;
		line-height: 1;
		position: absolute;
		top: 20.3rem;
		white-space: nowrap;
		width: 3.7rem;
	}
	.group-97-32V {
		align-items: center;
		background-color: #425563;
		border-radius: 2.2rem;
		box-sizing: border-box;
		display: flex;
		height: 3.1rem;
		left: 2.4rem;
		padding-right: 19px;
		position: absolute;
		top: 33.7rem;
		width: 9.6rem;

		.auto-group-kviq-wNm {
			align-items: center;
			background-color: #bed5c6;
			border-radius: 2.2rem 0 0 2.2rem;
			border-right: solid 0.1rem #2e3c46;
			box-sizing: border-box;
			color: #1a2228;
			display: flex;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 19px;
			font-weight: 400;
			height: 100%;
			justify-content: center;
			line-height: 1.2;
			margin-right: 1.559rem;
			white-space: nowrap;
			width: 4.841rem;
		}
		.no-AFX {
			color: #eceeef;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 19px;
			font-weight: 400;
			line-height: 1.2;
			white-space: nowrap;
		}
	}
	.group-98-true {
		align-items: center;
		background-color: #425563;
		border-radius: 2.2rem;
		box-sizing: border-box;
		display: flex;
		height: 30px;
		left: 50px;
		padding-right: 19px;
		position: absolute;
		width: 130px;

		.auto-group-jnqj-Q9s {
			cursor: pointer;
			align-items: center;
			background-color: #bed5c6;
			border-radius: 2.2rem 0 0 2.2rem;
			border-right: solid 0.1rem #2e3c46;
			box-sizing: border-box;
			color: #1a2228;
			display: flex;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 15px;
			font-weight: 400;
			height: 100%;
			justify-content: center;
			line-height: 1.2;
			margin-right: 1.559rem;
			white-space: nowrap;
			width: 60px;
		}

		.no-TitleSelected-Der {
			cursor: pointer;
			color: #eceeef;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 15px;
			font-weight: 400;
			line-height: 1.2;
			white-space: nowrap;
		}
	}

	.group-98-false {
		align-items: center;
		background-color: #bed5c6;
		border-radius: 2.2rem;
		box-sizing: border-box;
		display: flex;
		height: 30px;
		left: 50px;
		padding-right: 19px;
		position: absolute;
		width: 130px;

		.auto-group-jnqj-Q9s {
			cursor: pointer;
			align-items: center;
			background-color: #425563;
			border-radius: 2.2rem 0 0 2.2rem;
			border-right: solid 0.1rem #2e3c46;
			box-sizing: border-box;
			color: #eceeef;
			display: flex;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 15px;
			font-weight: 400;
			height: 100%;
			justify-content: center;
			line-height: 1.2;
			margin-right: 1.559rem;
			white-space: nowrap;
			width: 60px;
		}

		.no-TitleSelected-Der {
			cursor: pointer;
			color: #1a2228;
			flex-shrink: 0;
			font-family: Barlow;
			font-size: 15px;
			font-weight: 400;
			line-height: 1.2;
			white-space: nowrap;
		}
	}

	.dc0onewaypalletangledwithboxes-e1647027414919-1-oC1 {
		height: 419px;
		left: 62.7rem;
		object-fit: cover;
		position: absolute;
		top: 12.3rem;
		vertical-align: top;
		width: 62.4rem;
	}
}

/*Segunda parte*/
.cubicaje-add-row {
	color: var(--orange-200, #ffcabf);
	cursor: pointer;
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 600;
	line-height: 21.266px;
}

.cubicaje-container-footer {
	align-items: center;
	display: flex;
	margin-bottom: 32px;
	margin-top: 24px;
}

.cubicaje-container-main {
	align-items: center;
	background: var(--gray-800, #2e3c46);
	border-radius: 0.75em;
	display: flex;
	flex-direction: column;
	height: auto;
	margin-right: auto;
	width: 800px;
}

.cubicaje-container-page {
	margin-left: 12%;
}

.cubicaje-header {
	margin-bottom: 34px;
}

.cubicaje-inner {
	margin-top: 2em;
}

.cubicaje-information__btn-start-test {
	align-items: center;
	background: var(--orange-400, #ff8166);
	border-radius: 24px;
	display: flex;
	gap: 10px;
	justify-content: center;
	margin-bottom: 1em;
	margin-left: 32.2em;
	padding: 12px 20px;
	width: 13em;
	cursor: pointer;
}

.cubicaje-information__btn-text-start-test {
	color: var(--gray-900, #1a2228);
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 600;
	line-height: 21.266px;
}

.cubicaje-information__col-4 {
	height: auto;
	max-width: 100%;
	width: 100%;
}
.cubicaje-information__col-4 img{
	max-height: 300px;
    width: auto;
    margin: 0 auto;
    display: block;
}

.cubicaje-information__container-footer {
	align-items: center;
	display: flex;
	margin-bottom: 32px;
	margin-top: 24px;
}

.cubicaje-information__container-main {
	align-items: center;
	background: var(--gray-800, #2e3c46);
	border-radius: 0.75em;
	display: flex;
	flex-direction: column;
	height: auto;
	margin-right: auto;
}

.cubicaje-information__header {
	margin-bottom: 34px;
}

.cubicaje-information__multi-select {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border-radius: 4px 4px 0px 0px;
	box-shadow: 0px -1px 0px 0px #5e6f7a inset;
	display: flex;
	padding-left: 8px;
}

.cubicaje-information__principal-sub-title {
	color: var(--gray-200, #d4d8db);
	font-family: Barlow;
	font-size: 17.09px;
	font-style: normal;
	font-weight: 400;
	line-height: 23.926px;
}

.cubicaje-information__principal-title {
	color: var(--gray-100, #eceeef);
	font-family: Barlow;
	font-size: 27px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;

	svg {
		font-size: 15.64px !important;
	}
}

.cubicaje-information__question {
	color: #fff4f4;
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
	margin-bottom: 24px;
	margin-top: 24px;
}

.cubicaje-information__row {
	margin-top: 0.5em;
	max-height: 70px;
}

.cubicaje-information__sub-title {
	color: #fff;
	font-family: Barlow;
	font-size: 17.09px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
	margin-bottom: 24px;
	margin-top: 24px;
}

.cubicaje-information__svg-icon-text-footer {
	height: 24px;
	margin-right: 8px;
	width: 24px;
}

.cubicaje-information__tab-container {
	/* padding-top: 0.5em !important; */
}

.cubicaje-information__textbox-general {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-radius: 0.25em;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 3.125em;
	margin-bottom: 0.1em;
	padding-left: 0.5em;
	width: inherit;
	color: #eceeef;
}

.cubicaje-information__tab-container {
	width: 100%;
	/* padding-left: 2.5em; */
	height: auto;
}

.cubicaje-information__textbox-arancel {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-radius: 0.25em;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 3.125em;
	margin-left: 0;
	padding-left: 0.5em;
	width: 183px;
}

.cubicaje-information_fa-trash {
	cursor: pointer;
}

.cubicaje-information__row {
	margin-bottom: 0.5em;
}

.cubicaje-information__multi-select {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	box-shadow: 0px -1px 0px 0px #5e6f7a inset;
	padding-left: 8px;
}

.cubicaje__question {
	color: #fff4f4;
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 600;
	line-height: 23.926px;
	margin-bottom: 14px;
	max-width: 37em;
}

.cubicaje__tab-container {
	padding-top: 0.5em !important;
}

.cubicaje__row {
	margin-top: 0.5em;
}

.cubicaje_textbox-producto {
	align-items: flex-start;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-radius: 4px 0px 0px 0px;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 3.125em;
	padding-left: 0.5em;
	width: 380px;
	color: #eceeef;
}

.cubicaje_textbox-producto:focus {
	border-color: #ced4da;
	box-shadow: none;
}

.cubicaje_textbox-producto:focus-visible,
.cubicaje_textbox-arancel:focus-visible {
	outline: none;
}

.cubicaje_textbox-arancel {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-radius: 0px 4px 0px 0px;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 3.125em;
	padding-left: 0.5em;
	width: 177px;
	margin-left: -11em;
	color: #eceeef;
}

.cubicaje_container-textbox-arancel {
	margin-left: -1.2em;
}

.cubicaje_fa-trash {
	cursor: pointer;
	width: 14px;
	height: 16px;
	margin-left: 22px;
}

.css-13cymwt-control {
	width: 572px;
}

.cubicaje__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 0.75em;
	background: var(--gray-800, #2e3c46);
	width: 100%;
	height: auto;
	margin-left: 0;
}

.cubicaje__tabs {
	display: flex;
	border-bottom: 1px solid var(--gray-700, #425563);
	padding-left: 0;
	width: 50em;
	height: 60px;
}


.tab-test-cubicaje {
    flex: 1 1;
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    padding: 12px 0 7px;
    color: white;
    justify-content: center;
}
.tab-test-cubicaje.tab-active {
    font-weight: bold;
}

.tab-test-cubicaje .circle{
	margin-right: 0;
}
.cubicaje-test__tabs {
    border-bottom: 1px solid var(--gray-700, #425563);
    display: flex;
    height: 60px;
    padding: 0 10px 0 0;
    width: 100%;
}
.cubicaje__container-footer {
	border-top: 0.0625em solid var(--gray-700, #425563);
	display: block;
	padding: 20px 0;
	justify-content: flex-end;
	align-items: center;
	gap: 1em;
	align-self: stretch;
	width: 100%;
	height: 71px;
}

.cubicaje-information__inner {
	margin-top: 32px;
}

.cubicaje-information__dropdown-general {
	display: flex;
	padding-left: 0.5em;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 0.25em;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	width: 345px;
	height: 3.3em;
	margin-bottom: 0;
	color: var(--gray-300, #bbc2c7);
	font-feature-settings:
		'clig' off,
		'liga' off;
	font-family: inherit;
	font-size: inherit;
	font-style: normal;
	font-weight: 400;
	line-height: 1.49875em;
	align-items: center;
}

.cubicaje-information__dropdown-general option:not(:checked) {
	color: var(--gray-300, #bbc2c7);
}

.cubicaje__dropdown-general-full {
	display: flex;
	padding-left: 0.5em;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 0.25em;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	width: 572px;
	height: 3.3em;
	margin-top: 24px;
	margin-bottom: 24px;
	color: var(--gray-300, #bbc2c7);
	font-feature-settings:
		'clig' off,
		'liga' off;
	font-family: Barlow;
	font-size: 0.949375em;
	font-style: normal;
	font-weight: 400;
	line-height: 1.49875em;
	align-items: center;
}

.cubicaje__dropdown-general-middle {
	display: flex;
	padding-left: 0.5em;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 0.25em;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	width: 510px;
	height: 3.3em;
	color: var(--gray-300, #bbc2c7);
	font-feature-settings:
		'clig' off,
		'liga' off;
	font-family: Barlow;
	font-size: 0.949375em;
	font-style: normal;
	font-weight: 400;
	line-height: 1.49875em;
	align-items: center;
}

.cubicaje__label {
	color: var(--gray-300, #bbc2c7);
	font-feature-settings:
		'clig' off,
		'liga' off;
	font-family: Barlow;
	font-size: 15.19px;
	font-style: normal;
	font-weight: 600;
	line-height: 21.266px;
}

.animationTest__container {
	flex-shrink: 0;
	max-width: 600px;
	/* Ancho máximo del contenedor */
	margin: 0 auto;
	/* Centrar el contenedor en la página */
	padding: 20px;
	/* Espaciado interior del contenedor */
	text-align: left;
	/* Alineación del texto a la izquierda */
	border-radius: 12px;
	background: var(--gray-800, #2e3c46);
}

.animationTest__titleCubicaje {
	margin-top: 0;
	/* margin-left: 25px; */
	/* width: 266px; */
	/* height: 37px; */
	flex-shrink: 0;
	color: var(--gray-200, #d4d8db);
	font-family: Barlow;
	font-size: 21.22px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px; /* 124.485% */
}

.animationTest__pCubicaje {
	/* margin-left: 25px; */
	margin-top: 15px;
	/* width: 261px; */
	/* height: 134px; */
	flex-shrink: 0;
	color: var(--gray-200, #d4d8db);
	font-family: Barlow;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 140% */
	margin-bottom: 25px;
}

button {
	font-size: 16px;
	/* Tamaño de fuente del botón */
	cursor: pointer;
	/* Cambiar el cursor al pasar por encima del botón */
}

button span {
	margin-left: 5px;
	/* Espacio a la izquierda del icono de página */
}

.animationTest__title {
	color: var(--gray-200, #d4d8db);
	font-family: Barlow;
	font-size: 19.22px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px;
	/* 124.485% */
}

.cubicaje-information__container-page {
	margin: 40px auto 0;
	max-width: 1225px;
	width: 100%;
}
.cubicaje-information_row {
	display: flex;
	gap: 30px;
	justify-content: space-between;
	flex-direction: column-reverse;
}
.animationTest__image-container {
	margin-top: 20px;
}

.animationTest__image-container img {
	max-width: 100%;
	/* Asegurarse de que la imagen no exceda el ancho del contenedor */
	height: 230px;
	/* Mantener la proporción de aspecto de la imagen */
	display: block;
	/* Evitar el espacio adicional debajo de la imagen */
	margin: 0 auto;
	/* Centrar la imagen dentro de su contenedor */
}

.animationTest__next {
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 1.25em;
	background: var(--green-500, #adcab8);
	cursor: pointer;
	display: flex;
	/* padding: 0; */
	justify-content: center;
	align-items: center;
	gap: 0.625em;
	position: absolute;
	margin-left: 25px;
	margin-top: 5px;
}

.animationTest__next:hover {
	border-radius: 1.25em;
	background: var(--green-400, #bed5c6);
	cursor: pointer;
}

.animationTest__next-text {
	margin-right: 5px;
	color: var(--gray-900, #1a2228);
	font-family: Barlow;
	font-size: 0.84375em;
	font-style: normal;
	font-weight: 600;
	line-height: 1.3125em;
}

.animationTest__container-footer {
	border-top: 0.0625em solid var(--gray-700, #425563);
	display: flex;
	justify-content: flex-start;
	/* Alinea los elementos a la izquierda */
	align-items: center;
	gap: 1em;
	width: 100%;
	height: 71px;
	padding: 0 2.5em;
	/* Ajusta el padding según tus necesidades */
	position: relative;
	/* Ajusta la posición según tus necesidades */
}

.cubicaje__next {
	display: flex;
	padding: 0.625em 1em;
	justify-content: center;
	align-items: center;
	gap: 0.625em;
	position: absolute;
	left: 87%;
	transform: translateX(-50%);
	cursor: pointer;
	margin-right: 0.625em;
	cursor: pointer;
	border-radius: 1.25em;
	background: var(--green-500, #adcab8);
}

.cubicaje-information__error-container {
	opacity: 0;
	display: flex;
	align-items: center;
}

.cubicaje-information__error-container.show {
	opacity: 1;
}

.cubicaje-information__error-message {
	color: #f8a0d2;
	font-feature-settings:
		'clig' off,
		'liga' off;
	font-family: Barlow;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
	/* 100% */
}

.cubicaje-information__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 0.75em;
	background: var(--gray-800, #2e3c46);
	height: auto;
	margin-left: 0;
}

.cubicaje-information__next {
	margin-top: 1em;
	display: flex;
	padding: 0.625em 1em;
	justify-content: center;
	align-items: center;
	gap: 0.625em;
	position: absolute;
	right: 0;
	
	cursor: pointer;
	margin-right: 0;
	cursor: pointer;
	border-radius: 1.25em;
	background: var(--green-500, #adcab8);
}

.cubicaje-information__textbox-general {
	align-items: flex-start;
	align-self: stretch;
	background: var(--gray-700, #425563);
	border: 0.0625em solid transparent;
	border-top-width: 0.0625em;
	border-right-width: 0.0625em;
	border-bottom-width: 0.0625em;
	border-left-width: 0.0625em;
	border-radius: 0.25em;
	box-shadow: 0px 0.125em 0.25em rgba(0, 0, 0, 0.1);
	display: flex;
	height: 3.125em;
	margin-bottom: 0.1em;
	padding-left: 0.5em;
	width: inherit;
	color: #eceeef;
	margin-top: 1em;
}

.cubicaje-information_resume-title {
	color: #fff4f4;
	font-family: Raleway;
	font-size: 34.64px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px; /* 69.07% */
}

.cubicaje-information_resume-card-title {
	color: var(--gray-800, #2e3c46);
	font-family: Barlow;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px; /* 99.692% */
}

.cubicaje-information_resume-card-text {
	color: var(--gray-800, #2e3c46);
	font-family: Barlow;
	font-size: 13.5px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px; /* 177.23% */
}

.cubicaje-information_card {
	width: 189px;
	height: 79px;
	flex-shrink: 0;
	border-radius: 8px;
	background: var(--green-300, #cedfd5);
}

.cubicaje-information_title {
	text-align: end;
	color: #fff4f4;
	font-family: Barlow;
	font-size: 17px;
	font-style: normal;
	font-weight: 700;
	line-height: 23.926px; /* 157.512% */
}

.cubicaje-information_text {
	color: #fff;
	font-family: Barlow;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding-top: 0.3em;
}

.cubicaje-information__principal-title-text {
	padding-right: 0.5em;
}

.errorInputValidation {
	font-family: Barlow;
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0px;
	text-align: left;
	color: #f8a0d2;
	margin: 0;
	margin-left: 8px;
}

.errorContainer {
	align-items: center;
	margin: 10px 0 10px -10px;
}

.inputContainer {
	width: 100%;
	margin-right: 10px;
}

.weightContainer {
	/* margin-left: 10px; */
}

.shortInput {
	/* padding-left: 10px; */
}



@media (min-width: 768px) {
	.tab-test-cubicaje {
		display: flex;
		font-size: 15px;
		align-items: center;
		width: auto;
		flex-direction: row !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0.75em 1.25em;
		border-bottom: 0.1875em solid #2E3C46;
		cursor: pointer;
		min-width: 5em;
		margin-top: 1em;
		margin-bottom: 1em;
		margin-left: 0.4375em;
		margin-right: 0.4375em;
		flex: inherit;
		gap: 12px;
	}
	.rowcubicaje-container {
		display: grid;
		grid-template-columns: 80px 1fr 1fr 1fr;
		/* grid-template-rows: auto auto; */
		gap: 15px;
	}
	.rowcubicaje-container--doble{
		grid-template-columns: 80px 1fr ;
	}
	

	.rowcubicaje-container-doblerow {
		display: grid;
		grid-template-columns: 80px 1fr; /* Dos columnas: 80px para rowcubicaje-min y el resto para rowcubicaje-rightmin */
		grid-template-rows: auto auto; /* Dos filas: una para los inputs y otra para el error */
		gap: 10px; /* Espacio entre filas y columnas, ajustable según necesidad */
	}

	.rowcubicaje-min {
		grid-column: 1; /* Ocupa la primera columna */
		grid-row: 1; /* Ocupa la primera fila */
	}

	.rowcubicaje-rightmin {
		grid-column: 2; /* Ocupa la segunda columna */
		grid-row: 1; /* Ocupa la primera fila */
	}

	.rowcubicaje-container-doblerow .errorContainer {
		grid-column: 1 / -1; /* Ocupa ambas columnas en la segunda fila */
		grid-row: 2; /* Ocupa la segunda fila */
		display: flex; /* Para alinear el contenido dentro */
		align-items: center; /* Alinear verticalmente */
		gap: 5px; /* Espacio entre la imagen y el texto */
	}

	.squareItemsContainer{
		flex-direction: row;
	}
}

@media (min-width: 1024px) {
	
	.cubicaje-information_row {
		flex-direction: row;
		align-items: flex-start;
		gap: 0;
	}
	.cubicaje-information__col-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	.auto-group-6qah-KNM .auto-group-pspf-ddw .group-84-cXo {
		flex-direction: row;			
		
	}
	
	.auto-group-6qah-KNM .auto-group-pspf-ddw .group-84-cXo .auto-group-faqu-S9T,
	.auto-group-6qah-KNM .auto-group-pspf-ddw .group-84-cXo .auto-group-pxb3-aus,
	.auto-group-6qah-KNM .auto-group-pspf-ddw .group-84-cXo .auto-group-6spv-9Gq {
		margin-right: 0;
        flex: 1;
	}
	.auto-group-6qah-KNM .auto-group-pspf-ddw .group-84-cXo {
		padding-right: 20px;
	}
	.col_sticky{
		position: sticky;
		top: 20px;
		z-index: 9999;
	}
				
	
}

@media (min-width: 1440px) {}