.mainContainerCubicajeResumen {
  padding: 20px;
  background-color: #2e3c46;
  border-radius: 12px;
}

.mainContainerCubicajeResumen h2 {
  font-family: Raleway;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
}

.mainContainerCubicajeResumen h3 {
  font-family: Barlow;
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #cedfd5;
  margin-top: -10px;
}

.squareItemsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  flex-direction: column;
  gap: 15px;
}

.itemContainer {
  padding: 12px 24px 12px 24px;
  border-radius: 8px;
  background-color: #cedfd5;
  width: 100%;
}

.itemContainer h5 {
  font-family: Barlow;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e3c46;
}

.itemContainer p {
  font-family: Barlow;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e3c46;
}

.descriptionMainContainer {
  justify-content: space-between;
  align-items: self-start;
}

.descriptionMainContainer h6 {
  font-family: Raleway;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
  align-self: self-start;
  width: 30%;
}

.middleColumnText p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
  margin: 0;
  margin-bottom: 12px;
  margin-left: 0px;
}

.rightColumn {
  /* margin-left: 14%; */
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  width: 100%;
}

.rightColumn p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
  margin: 0;
  margin-bottom: 12px;
  margin-left: 0px;
}

.rowContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
  margin-bottom: 24px;
}

.mainContainerFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.0625em solid var(--gray-700, #425563);
  display: flex;
  padding: 1em 2.5em;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  align-self: stretch;
  width: 100%;
  height: 71px;
  align-items: center;
  position: relative;
  margin-top: 16px;
}

.leftFooterContainer {
  display: flex;
  align-items: center;
  margin-bottom: -20px;
}

.newCalButton {
  display: flex;
  padding: 0.625em 1em;
  justify-content: center;
  cursor: pointer;
  width: 150px;
  border-radius: 1.25em;
  background: #ff8166;
  margin-top: 20px;
}


@media (min-width: 1024px) {
  .squareItemsContainer{
    flex-direction: row;
  }
  .descriptionMainContainer .rowContainer {
    flex-direction: row;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    align-items: start;
    column-gap: 15px;
  }
  .descriptionMainContainer .rightColumn,
  .descriptionMainContainer h6{
    width: inherit;
  }
  .mainContainerCubicajeResumen{
    padding: 40px 35px;
  }
  .frame-111-M3B {
    margin: 0 -35px;
    padding: 20px 35px 0;
  }


  .mainContainerCubicajeResumen h2{
    font-size: 35px;
    line-height: 48px;
  }
}

